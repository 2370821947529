import { NextApi } from 'src/redux/service/Next/next';

export const NOTIFICATION_EVENTS = 'NOTIFICATION_EVENTS';
export const MODAL_NOTIFICATION_EVENTS = 'MODAL_NOTIFICATION_EVENTS';

export const getNotificationEventsNext = new NextApi(`analytics/notification-events`, NOTIFICATION_EVENTS, {
  method: 'GET', json: false,
});

export const getModalNotificationEventsNext = new NextApi(`analytics/notification-events`, MODAL_NOTIFICATION_EVENTS, {
  method: 'GET', json: false,
});

import {getDeviceTypesIdsNext, getDeviceTypesNext} from "./actionTypes";


const initialState = {
  isLoading: true,
  list: null,
  listIds: null,
  isLoadingListIds: true
}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case getDeviceTypesNext.INIT:
      return {...state, isLoading: true, list: null}
    case getDeviceTypesNext.SUCCESS:
      return {...state, isLoading: false, list: payload}
    case getDeviceTypesNext.ERROR:
      return {...state, isLoading: false, list: null}
    case getDeviceTypesIdsNext.INIT:
      return {...state, isLoadingListIds: true, listIds: null}
    case getDeviceTypesIdsNext.SUCCESS:
      return {...state, isLoadingListIds: false, listIds: payload}
    case getDeviceTypesIdsNext.ERROR:
      return {...state, isLoadingListIds: false, listIds: null}
    default:
      return state
  }
}


import React from 'react';
import { CFormGroup, CLabel} from "@coreui/react";
import ReactSlider from "react-slider";
import classNames from "classnames";
import './IensoSlider.scss'
import PropTypes from "prop-types";
export const IensoSlider = React.memo(({label, id, minVal, maxVal, handleChange, step, disabled}) => {

  return (
    <div className={'ienso-slider'}>
      <CFormGroup>
        <CLabel htmlFor={id}>{label}</CLabel>
        <br/>
        <ReactSlider
          disabled={disabled}
          id={id}
          min={minVal}
          max={maxVal}
          step={step}
          onChange={handleChange}
          className={classNames('horizontal-slider', disabled && 'disabled')}
          thumbClassName={classNames("example-thumb", disabled && 'disabled')}
          trackClassName={classNames("example-track", disabled && 'disabled')}
        />
      </CFormGroup>
    </div>
  );
})

IensoSlider.defaultProps = {
  label: '',
  id: 'slider',
  minVal: 0,
  maxVal: 10,
  handleChange: () => undefined,
  step: 1,
  disabled: false
}
IensoSlider.propTypes = {
  label: PropTypes.string,
  id:  PropTypes.string,
  minVal: PropTypes.number,
  maxVal: PropTypes.number,
  handleChange: PropTypes.func,
  step: PropTypes.number,
  disabled: PropTypes.bool
}

import React from 'react';
import {IensoMultiSelectionCategories} from "src/iensoink/ui-components";
import classnames from "classnames";
import './FilterCategories.scss'

export const FilterCategories = React.memo( ({onFilterChange, label, selected, filterId, list, customFilterClassName}) => {
  const combineSelectedValues = (mappedValues) => {
    const combinedValues = mappedValues.reduce((array, item) => {

      // for sub catercories
      if (item?.items) {
        item?.items?.forEach((item) => {
          if (item?.selected) array.push(item.value);
        });
      }
      // for non-sub categories
      else if (item?.selected) {
        array.push(item.value);
      }

      return array
    }, [])
    return combinedValues;
  }

  return (
    <div className={classnames('filter-categories', customFilterClassName)}>
      <IensoMultiSelectionCategories
        placeholder={label}
        onChange={(values, mappedValues) => {
          onFilterChange(combineSelectedValues(mappedValues), filterId)
        }}
        items={list} selected={selected}/>
    </div>
  );
})

FilterCategories.defaultProps = {
  onFilterChange: () => undefined,
  list: null,
  key: '',
  label: '',
  selected: false,
  filterId: '',
}

import {NextApi} from "../../../../../redux/service/Next/next";

export const GET_PACKAGES_LIST = 'GET_PACKAGES_LIST'
export const GET_PACKAGE_FEATURES_LIST = 'GET_PACKAGE_FEATURES_LIST'
export const DELETE_PACKAGE = 'DELETE_PACKAGE'
export const CREATE_PACKAGE = 'CREATE_PACKAGE'
export const SELECT_PACKAGE = 'SELECT_PACKAGE'
export const SELECT_PACKAGE_ITEM = 'SELECT_PACKAGE_ITEM'
export const EDIT_PACKAGE="EDIT_PACKAGE"

export const getPackageListNext = new NextApi('whitelabel/packages', GET_PACKAGES_LIST, {
  method: 'GET', json: false
})
export const getPackageFeaturesListNext = new NextApi('whitelabel/packages/features', GET_PACKAGE_FEATURES_LIST, {
  method: 'GET', json: false
})
export const deletePackageNext = new NextApi(`whitelabel/packages/{packageId}`, DELETE_PACKAGE, {
  method: 'DELETE', json: false,
});

export const editPackageNext = new NextApi('whitelabel/packages/{packageId}', EDIT_PACKAGE, {
  method: 'PUT', json: true,
});

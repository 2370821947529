import {getRealmNameNext} from "./actionTypes";
import {setValueToLocalStorage} from "../../../service/utils/utilFunctions";
import {localStorageConfig} from "../../../config";

const initialState = {
  isLoading: true,
  realmName: null,
  displayName: null,
  whiteLabelConfigData: null,
  isError: false,
  availableOperatorRoles: null,
  whitelabelInfo: null,
  buttonColor:null
}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case getRealmNameNext.INIT:
    case getRealmNameNext.SUCCESS:
      setValueToLocalStorage(localStorageConfig.REALM_NAME, payload?.name)
      document.querySelector(':root').style.setProperty('--brand-color', payload?.brandColor);
      return {
        ...state,
        whiteLabelConfigData: payload,
        whitelabelInfo: payload,
        realmName: payload?.name,
        displayName: payload?.displayName,
        isLoading: false,
        availableOperatorRoles: payload?.operatorRoles
      }
    case getRealmNameNext.ERROR:
      return {...state, isError: true}
    default:
      return state
  }
}


const initialState = {
  list: [
    {
      time: '10/01/21  08:11',
      activity: 'Malicious process (malware)',
      priority: 'high',
      description: 'Detected malicious process [1040:/bin..',
      result: 'Blocked'
    },
    {
      time: '10/01/21  08:11',
      activity: 'Malicious process (malware)',
      priority: 'high',
      description: 'Detected malicious process [1040:/bin..',
      result: 'Blocked'
    },
    {
      time: '10/01/21  08:11',
      activity: 'Malicious process (malware)',
      priority: 'high',
      description: 'Detected malicious process [1040:/bin..',
      result: 'Blocked'
    },
    {
      time: '10/01/21  08:11',
      activity: 'Malicious process (malware)',
      priority: 'high',
      description: 'Detected malicious process [1040:/bin..',
      result: 'Blocked'
    },
  ]
}

export const reducer = (state = initialState, {type, ...rest}) => {
  switch (type) {

    default:
      return state
  }
}


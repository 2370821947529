import React from 'react';
import {
  CHeader,
  CToggler,
  CSubheader,
  CHeaderNav,
  CBreadcrumbRouter
} from '@coreui/react'
import PropTypes from "prop-types";
import classnames from "classnames";

import './IensoHeader.scss';
import {SidebarToggleIcon} from "src/iensoink/ui-components/icons/nav";

export const IensoHeader = React.memo( ({
                              children,
                              isDashboard,
                              sidebarShow,
                              openSidebar,
                              closeSidebar,
                              routes,
                              languageDropdown,
                              logoDropdown,
                              notificationDropdown,
                              brandColor
                            }) => {
  if (isDashboard) routes = routes.slice(1)

  return (
    <div className={classnames('ienso-header-layout', {
      'ienso-header-layout__hidden-sidebar': sidebarShow !== 'responsive'
    })}>
      <CHeader withSubheader>
        <div className={'toggle-btn-wrapper'}>
          <CToggler
            inHeader
            className="ml-md-3 d-lg-none"
            onClick={openSidebar}
          ><SidebarToggleIcon fill={brandColor}/></CToggler>
          <CToggler
            inHeader
            className="ml-3 d-md-down-none"
            onClick={closeSidebar}
          ><SidebarToggleIcon fill={brandColor}/>
          </CToggler>
        </div>
        {children}
        <CHeaderNav className="px-3">
          {languageDropdown}
          {notificationDropdown}
          {logoDropdown}
        </CHeaderNav>
        <CSubheader className="px-3">
          <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0" routes={routes}/>
        </CSubheader>


      </CHeader>
      <div className={'bottom-divider'}/>
    </div>
  );
})

IensoHeader.propTypes = {
  children: PropTypes.node,
  sidebarShow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  openSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  routes: PropTypes.array,
  logoDropdown: PropTypes.node,
  notificationDropdown: PropTypes.node
}

IensoHeader.defaultProps = {
  children: null,
  sidebarShow: false,
  openSidebar: () => undefined,
  closeSidebar: () => undefined,
  routes: [],
  logoDropdown: null,
  notificationDropdown: null
}

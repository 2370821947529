import React, { useEffect, useState} from 'react';
import './IensoSelect.scss'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu, CInputGroup,
  CDropdownToggle, CFormGroup, CInputGroupPrepend, CInputGroupText, CLabel,
} from "@coreui/react";
import { IensoTooltip } from "src/iensoink/ui-components";
import '../IensoInputsGroup.scss'
import PropTypes from "prop-types";
import classnames from "classnames";
import {IensoErrorMsg} from "../error-msg/IensoErrorMsg";

export const IensoSelect = React.memo(
  ({
    label,
    icon,
    options,
    id,
    value,
    useIcon,
    onChange,
    invalid,
    errorMsg,
    disabled,
    placeholder,
    addPostfix,
    tooltipText,
    tooltipIcon,
    useTooltip,
  }) => {
    const [flagPlaceholder, setFlagPlacehodler] = useState(true);
    useEffect(() => {
      if (value) setFlagPlacehodler(false);
    }, [value]);

    const showSelected = () => {
      let item = options?.find((item) => item?.value === value)?.label;
      return item ? (
        <CDropdownToggle
          className={"dropdown-custom text-body-1 title-selected-item"}
        >
          {item}
          {addPostfix ? "s" : ""}
          &nbsp;
        </CDropdownToggle>
      ) : (
        <CDropdownToggle className={"dropdown-custom text-body-3"}>
          {placeholder}
          {addPostfix ? "s" : ""}
          &nbsp;
        </CDropdownToggle>
      );
    };

    return (
      <div
        className={classnames(
          "ienso-input-group text-input ienso-select-wrapper  ienso-inputs-group",
          disabled && "group-disabled",
          useIcon ? "input-rounded-border-right" : "input-rounded-border"
        )}
        id={id}
      >
        <CFormGroup>
          <CLabel>
            {label}{" "}
            {useTooltip && (
              <IensoTooltip text={tooltipText}>{tooltipIcon}</IensoTooltip>
            )}
          </CLabel>
          <CInputGroup>
            {useIcon && (
              <CInputGroupPrepend>
                <CInputGroupText
                  className={classnames(
                    "text-white",
                    invalid && "input-group-text-invalid"
                  )}
                >
                  <div className={"text-field-icon"}>{icon}</div>
                </CInputGroupText>
              </CInputGroupPrepend>
            )}
            <div
              className={classnames(
                value && "selected",
                "ienso-select",
                invalid && "ienso-select-invalid"
              )}
            >
              <CDropdown
                inNav
                className={classnames(
                  "ienso-select-dropdown",
                  flagPlaceholder && "show-placeholder"
                )}
              >
                  {showSelected()}

                <CDropdownMenu className="dropdown-select">
                  {options?.map((item) => (
                    <CDropdownItem
                      id={item?.value}
                      key={item?.value}
                      disabled={value === item?.value}
                      className={classnames(
                        value === item?.value
                          ? "selected-item text-body-1"
                          : "text-body-3"
                      )}
                      onClick={onChange}
                    >
                      {item?.label}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            </div>
            {invalid && <IensoErrorMsg msg={errorMsg} />}
          </CInputGroup>
        </CFormGroup>
      </div>
    );
  }
);

IensoSelect.propTypes = {
  options: PropTypes.array,
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string
}

IensoSelect.defaultProps = {
  options: null,
  id: '',
  label: '',
  placeholder: null,
  disabled: false,
  addPostfix: false,
  useIcon: false,
  useTooltip: false,
}

import { NextApi } from '../../../redux/service/Next/next';

export const AUDIT_LOG_GET = 'AUDIT_LOG_GET';
export const OPERATOR_LIST_GET = 'OPERATOR_LIST_GET';

export const getAuditLogsNext = new NextApi('audit-service', AUDIT_LOG_GET, {
  method: 'GET', json: false,
});

export const getOperatorListNext = new NextApi('whitelabel/operators', OPERATOR_LIST_GET, {
  method: 'GET', json: false,
});
import { getSpaceByIdNext} from "./actionType";

const initialState = {
  data:null,
  isLoading:true
}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case getSpaceByIdNext.SUCCESS:
      return {...state, isLoading: false, data:payload }
    case getSpaceByIdNext.ERROR:
      return {...state, isLoading: false, data:null }
    default:
      return state
  }
}

import {NextApi} from "../Next/next";

export const APP_GET_REALM_NAME = "APP_GET_REALM_NAME"
export const WHITELABEL_API_URL_PATH = "/whitelabel/config"

export const getRealmNameNext = new NextApi(WHITELABEL_API_URL_PATH, APP_GET_REALM_NAME, {
  method: "GET", json: false,
  headers: {
    Authorization: undefined
  }
});

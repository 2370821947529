import {
  restUserPasswordNext,
  SET_USER_ROLES,
  userDataNext,
  userRolesNext,
} from "./actionTypes";
import { createHttpAction } from "../Next/http-fetch";

export const getUserData = () => createHttpAction(userDataNext, null).action;

export const getUserRoles = (userId) =>
  createHttpAction(userRolesNext, null, { params: { userId } }).action;

export const setUserRoles = (userRoles) => ({
  type: SET_USER_ROLES,
  userRoles,
});

export const restUserPassword = (onError, onSuccess, userId) =>
  createHttpAction(restUserPasswordNext, null, {
    onError,
    onSuccess,
    params: { userId },
  }).action;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLoginStatus } from "../service/utils/keycloak/utilFunstions";
import {
  accessTokenSelector,
  isLoadingTokenSelector,
} from "../redux/service/auth/selectors";
import { IensoLoader } from "src/iensoink/ui-components";
import { getRoles } from "../service/utils/utilFunctions";
import {
  isLoadingUserDataSelector,
  userRolesSelector,
} from "../redux/service/user-info/selectors";
import {
  getUserData,
  setUserRoles,
  getUserRoles,
} from "../redux/service/user-info/actions";
import { realmNameSelector } from "../redux/service/realm-info/selectors";

const UserWrapper = ({ children }) => {
  const isLoadingToken = useSelector(isLoadingTokenSelector);
  const accessToken = useSelector(accessTokenSelector);
  const realmName = useSelector(realmNameSelector);
  const dispatch = useDispatch();
  const userRoles = useSelector(userRolesSelector);
  const isLoadingUserData = useSelector(isLoadingUserDataSelector);

  useEffect(() => {
    if (realmName || !accessToken) checkLoginStatus(realmName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realmName]);

  useEffect(() => {
    if (accessToken) {
      const tokenData = JSON.parse(atob(accessToken?.split(".")[1]));
      const userKeycloakId = tokenData.sub;
      //get user's roles
      dispatch(getUserRoles(userKeycloakId));
      //get realm's roles
      const roles = getRoles(tokenData);
      dispatch(setUserRoles(roles));
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return isLoadingToken || !accessToken || !userRoles || isLoadingUserData ? (
    <IensoLoader />
  ) : (
    children
  );
};

export default UserWrapper;

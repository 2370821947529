import React from 'react';
import './IensoTableFooter.scss'
import {Pagination} from "./pagination/Pagination";
import { translate } from "src/redux/service/i18n";

export const IensoTableFooter = React.memo(({totalItems, currentPage, onFilterChange, limit, itemsAmount}) => {
  return (
    <div className={'ienso-table-footer'}>
      <span
        className={'title'}>{totalItems && totalItems !== 0 && `${translate("IensoTableFooter.Showing")} ${currentPage * limit - limit + 1} - ${currentPage * limit > totalItems ? currentPage * limit - (limit - itemsAmount) : currentPage * limit} of ${totalItems}`}</span>
      {totalItems && totalItems !== 0 &&
      <Pagination totalItems={totalItems} currentPage={currentPage} onActivePageChange={onFilterChange}
                  limit={limit}/>}
    </div>
  );
})

IensoTableFooter.defaultProps = {
  totalItems: 0,
  currentPage: 1
}

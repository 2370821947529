import React from 'react';
import './IensoEmptyImgView.scss'
import {IensoButton} from "src/iensoink/ui-components/components/IensoButton/IensoButton";
import PropTypes from "prop-types";
import classnames from "classnames";

export const IensoEmptyImgView =  React.memo(({name, className}) => {
  let chars = name?.split(' ')
  if (chars[0] && chars[1]) chars = `${(chars[0][0]).toUpperCase()} ${(chars[1][0]).toUpperCase()}`
  if (chars[0] && !chars[1]) chars = `${(chars[0][0]).toUpperCase()}`
  if (!chars[0] && chars[1]) chars = `${(chars[1][0]).toUpperCase()}`

  return (
    <div className={'ienso-empty-img-container'}>
      <div className={classnames('ienso-empty-img-container__wrapper', className)}>
        <span>{chars}</span>
      </div>
    </div>
  );
})

IensoEmptyImgView.defaultProps = {
  name: 'User',
  className: null
}
IensoButton.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string
}

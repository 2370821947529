import React from 'react';
import { Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import {
  redirectToLoginUrl,
  redirectToLogoutUrl
} from "./service/utils/keycloak/utilFunstions";
import {useSelector} from "react-redux";

import {ERROR_404, ERROR_500, LOGIN, LOGOUT} from "./routes";
import {realmNameSelector} from "./redux/service/realm-info/selectors";

// Containers
const TheLayout = React.lazy(() => import('./layouts/TheLayout'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));


const AppRoutes = () => {
  const realmName =  useSelector(realmNameSelector)

  return (
    <>
      <Switch>
        <Route exact path={LOGIN} name="Login Page" render={() => redirectToLoginUrl(realmName)}/>
        <Route exact path={LOGOUT} name="Logout Page" render={() => redirectToLogoutUrl(realmName)}/>
        <Route exact path={ERROR_404} name="Page 404" render={props => <Page404 {...props}/>}/>
        <Route exact path={ERROR_500} name="Page 500" render={props => <Page500 {...props}/>}/>
        <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
      </Switch>
    </>
  );
};

export default AppRoutes;

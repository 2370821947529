import React, {useCallback, useEffect, useState} from 'react';
import {CTabs, CNav, CNavItem, CNavLink, CTabPane, CTabContent} from '@coreui/react'
import './IensoTabs.scss'
import {getValueFromLocalStorage, setValueToLocalStorage} from "../../../../service/utils/utilFunctions";
import {removeValueFromLocalStorage} from "../../utils/utils";
import {useSelector} from "react-redux";
import {brandColorSelector} from "../../../../redux/service/realm-info/selectors";
import classNames from "classnames";


const ACTIVE_TAB_KEY = 'ACTIVE_TAB_KEY'
export const IensoTabs = React.memo(({options, onActiveTabChange}) => {
  const brandColor = useSelector(brandColorSelector)
  const [activeTab, setActiveTab] = useState(Object.keys(options)[0])
  const onTabChange = useCallback(
    (activeTab) => {
      setActiveTab(activeTab);
      onActiveTabChange && onActiveTabChange(activeTab)
      setValueToLocalStorage(ACTIVE_TAB_KEY, activeTab)
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    const activeTabFromLocalStorage = getValueFromLocalStorage(ACTIVE_TAB_KEY)
    activeTabFromLocalStorage && setActiveTab(activeTabFromLocalStorage)
    return () => removeValueFromLocalStorage(ACTIVE_TAB_KEY)
  }, []);

  return (
    <div className={'ienso-tabs'}>
      <CTabs activeTab={activeTab} onActiveTabChange={onTabChange}>
        <CNav className={'tab-cnav-custom'} variant="tabs">
          {Object.keys(options).map(key =>
            <CNavItem key={key} className={classNames(options[key]?.disabled && "disabled")}>
              <div style={{borderBottom: activeTab === key && `3px solid ${brandColor}`}}>
                <CNavLink data-tab={key} disabled={options[key]?.disabled || false}>
                <span style={{
                  color: activeTab === key && brandColor,
                  fill: activeTab === key && brandColor,
                }}>
                  <div style={{display: 'inline-block', fill: 'inherit'}}>{options[key].icon}</div>
                  {options[key].label}
                </span>
                </CNavLink>
              </div>
            </CNavItem>)}
        </CNav>
        <CTabContent>
          {Object.keys(options).map(key =>
            <CTabPane key={key} data-tab={key}>
              {activeTab === key && options[key].data}
            </CTabPane>)}
        </CTabContent>
      </CTabs>
    </div>
  );
})

IensoTabs.defaultProps = {
  onActiveTabChange: () => undefined
}

import { getDeviceFeaturesListNext, } from "./actionTypes";


const initialState = {
  isLoading: true,
  data: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getDeviceFeaturesListNext.INIT:
      return { ...state, isLoading: true }
    case getDeviceFeaturesListNext.SUCCESS:
      return { ...state, data: payload, isLoading: false }
    case getDeviceFeaturesListNext.ERROR:
      return { ...state, isLoading: false }
    default:
      return state
  }
}


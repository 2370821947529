import { NextApi } from 'src/redux/service/Next/next';

export const SPACE_EVENTS = 'SPACE_EVENTS';
export const MODAL_SPACE_EVENTS = 'MODAL_SPACE_EVENTS';

export const getSpaceEventsNext = new NextApi(`analytics/space-events`, SPACE_EVENTS, {
  method: 'GET', json: false,
});

export const getModalSpaceEventsNext = new NextApi(`analytics/space-events`, MODAL_SPACE_EVENTS, {
  method: 'GET', json: false,
});

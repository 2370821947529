import {FilterBy} from "../filter_by/FilterBy";
import {IensoTableFiltersTypes} from "./IensoTableFilters";
import {FilterDatePicker} from "../filter_by_date/FilterDatePicker";
import {FilterCategories} from "../filter-categories/FilterCategories";
import React from "react";

const Filters =  React.memo(({values, options, onFilterChange}) => {
  return Object.keys(options).map(key => {
    switch (options[key].type) {
      case IensoTableFiltersTypes.select:
        return (
          <FilterBy
            label={options[key].label}
            list={options[key].list}
            onFilterChange={onFilterChange}
            selected={values[key] ?? options[key].defaultValue}
            filterId={key}
            customFilterClassName={options[key].customFilterClassName}
          />
        );

      case IensoTableFiltersTypes.date:
        return (
          <FilterDatePicker
            onFilterChange={onFilterChange}
            label={options[key].label}
            selected={values[key]}
            filterId={key}
          />
        );
      
      case IensoTableFiltersTypes.selectCategories:
        return (
          <FilterCategories
            list={options[key].list}
            onFilterChange={onFilterChange}
            label={options[key].label}
            selected={values[key]}
            filterId={key}
            customFilterClassName={options[key].customFilterClassName}
          />
        );
      default: return null
    }
  })
})

export default Filters;

import Next from './next';
import {store} from "../../../index";
import qs from 'query-string';
import {localStorageConfig} from "../../../config";
import {errorHandler} from "../../../service/utils/utilFunctions";


const errorFunctions = [];
const successFunctions = [];
const finishFunctions = [];

const defaultErrorResponse = {
  details: [],
  error: 'Errors.failed-to-fetch',
  message: 'Errors.failed-to-fetch-message',
  statusCode: 502,
  data: null,
};
const defaultSuccessResponse = {
  details: [],
  error: null,
  message: null,
  statusCode: 204,
  data: {},
};

export const onFinishMiddleware = (func) => {
  if (typeof func === 'function') {
    finishFunctions.push(func);
  } else {
    throw new Error('On Error middleware must be a function!');
  }
};

export const onErrorMiddleware = (func) => {
  if (typeof func === 'function') {
    errorFunctions.push(func);
  } else {
    throw new Error('On Error middleware must be a function!');
  }
};

export const onSuccessMiddleware = (func) => {
  if (typeof func === 'function') {
    successFunctions.push(func);
  } else {
    throw new Error('On Success middleware must be a function!');
  }
};

const dispatchActions = (actions) => {
  const actionsList = Array.isArray(actions) ? actions : [actions];
  actionsList.forEach(
    (action) => {
      if (action) {
        store.dispatch(typeof action === 'function' ? action() : action);
      }
    },
  );
};

const replaceQueryPlaceholders = (uri, params) => Object.keys(params).reduce(
  (currentUri, key) => (params[key]
    ? currentUri.replace(new RegExp(`{${key}}`, 'g'), (params[key]))
    : currentUri),
  uri,
);

const dispatchFinish = (actionData, data = defaultSuccessResponse, options, next) => {
  store.dispatch({
    type: next.FINALLY,
    payload: data,
    actionData: actionData,
  });
  if (options.onFinish) {
    dispatchActions(
      options.onFinish(data, options, next),
    );
  }
  finishFunctions.forEach(
    (errorFunction) => errorFunction(data, options, next),
  );
};

const dispatchSuccess = (actionData = {}, data = defaultSuccessResponse, options, next) => {
  store.dispatch({
    type: next.SUCCESS,
    payload: data,
    actionData: actionData,
  });
  if (options.onSuccess) {
    dispatchActions(
      options.onSuccess(data, options, next),
    );
  }
  successFunctions.forEach((successFuncs) => successFuncs(data, options, next));
};

const dispatchError = (actionData = {}, data = defaultErrorResponse, options, next, error) => {
  store.dispatch({
    type: next.ERROR,
    payload: data,
    actionData: actionData,
  });
  if (options.onError) {
    dispatchActions(
      options?.onError(data, options, next, error),
    );
  }
  errorFunctions.forEach(
    (errorFunction) => errorFunction(data, options, next, error),
  );
};
const textFailOver = async (response) => {
  const text = await response.text();
  try {
    return JSON.parse(text)
  } catch (e) {
    console.warn(e.message);
    return text;
  }
}

const getResponseData = (response, {responseType}) => {
  switch (responseType) {
    case 'blob':
      return response.blob();
    case 'buffer':
      return response.arrayBuffer();
    case 'json':
      return textFailOver(response);
    default:
      return response.text();
  }
};

const serialize = (data) => JSON.parse(JSON.stringify(data));

export const createHttpAction = (next, body, options = {}, actionData = {}) => {
  if (!(next instanceof Next) || !options) {
    throw new Error('Invalid Next Parameter!');
  }

  const controller = new AbortController();
  const {signal} = controller;

  const headers = {
    accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem(localStorageConfig.AUTH_TOKEN)}`,
    ...(next.options.headers || {}),
    ...(options.headers || {}),
  };

  const combinedOptions = {
    responseType: 'json',
    ...next.options,
    ...options,
    headers: serialize(headers),
  };

  const url = qs.stringifyUrl({
    url: replaceQueryPlaceholders(next.uri, combinedOptions.params || {}),
    query: combinedOptions.query || {},
  });
  const current = fetch(url,
    {
      signal,
      credentials: 'include',
      ...combinedOptions,
      body: body
        ? (
          combinedOptions.json
            ? JSON.stringify(body)
            : body
        )
        : undefined,
    }).then(
    (response) => getResponseData(response, combinedOptions).then(
      (data) => {
        if (response.ok) {
          dispatchSuccess(actionData, data, combinedOptions, next);
        } else {
          errorHandler(data?.status)
          dispatchError(actionData, data, combinedOptions, next);
        }
        dispatchFinish(actionData, data, combinedOptions, next);
      },
    ),
  ).catch(
    (error) => {
      dispatchError(actionData, defaultErrorResponse, combinedOptions, next, error);
      dispatchFinish(actionData, defaultErrorResponse, combinedOptions, next);
    },
  );

  return {
    action: {
      type: next.INIT,
      data: body,
      actionData,
    },
    request: current,
    controller: controller,
  };
};

import { NextApi } from "../../../../redux/service/Next/next";

export const GET_SPACES_BY_CLIENT_ID = "GET_SPACES_BY_CLIENT_ID";
export const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";
export const DELETE_CLIENT_BY_ID = "DELETE_CLIENT_BY_ID"
export const GET_DEVICES_BY_CLIENT_ID = "GET_DEVICES_BY_CLIENT_ID";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const MARK_CLIENT_FOR_DELETION = "MARK_CLIENT_FOR_DELETION";
export const REACTIVATE_CLIENT = 'REACTIVATE_CLIENT'
export const SELECT_DEVICE = 'SELECT_DEVICE';
export const GET_PACKAGE_LIST = 'GET_PACKAGE_LIST';
export const CHANGE_SPACE_PACKAGE = 'CHANGE_SPACE_PACKAGE';
export const UPDATE_CLIENT_DETAILS = 'UPDATE_CLIENT_DETAILS';

export const changeSpacePackageNext = new NextApi('space/{spaceId}', CHANGE_SPACE_PACKAGE, {method: 'PUT'});

export const getRealmPackageListNext = new NextApi(
  "whitelabel/packages?limit=9999&page=1",
  GET_PACKAGE_LIST,
  {
    method: "GET",
    json: false,
  }
);

export const getSpacesListByClientIdNext = new NextApi(
  "space/client/spaces",
  GET_SPACES_BY_CLIENT_ID,
  {
    method: "GET",
    json: false,
  }
);

export const getClientByIdNext = new NextApi(
  "user-info/operator/users/{clientId}",
  GET_CLIENT_BY_ID,
  {
    method: "GET",
    json: false,
  }
);

export const deleteClientByIdNext = new NextApi(
  "user-info/operator/users/{clientId}",
  DELETE_CLIENT_BY_ID,
  {
    method: "DELETE",
    json: false,
  }
);

export const getDevicesByClientIdNext = new NextApi(
  "device-manager/client/users/{clientId}",
  GET_DEVICES_BY_CLIENT_ID,
  {
    method: "GET",
    json: false,
  }
);

export const markClientForDeletionNext = new NextApi(
  "user-info/operator/users/{clientId}/mark-deletion",
  MARK_CLIENT_FOR_DELETION,
  {
    method: "PUT",
    json: false,
  }
);

export const reactivateClientNext = new NextApi(
  "user-info/operator/users/{clientId}/reactivate",
  REACTIVATE_CLIENT,
  {
    method: "PUT",
    json: false,
  }
);

export const updateClientDetailsNext = new NextApi(
  "user-info/operator/users/{clientId}",
  UPDATE_CLIENT_DETAILS,
  {
    method: "PUT",
    json: true,
  }
);
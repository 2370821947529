import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useSelector} from 'react-redux';
import {store} from "../../../index";

export const translate = (textKey = '', params = {}) => {
  const texts = store?.getState()?.i18n?.texts || {};

  if (!texts[textKey]) {
    // eslint-disable-next-line no-console
    console.warn('Missing Key for ', textKey);
  }

  return Object.keys(params).reduce(
    (res, key) => res.replace(new RegExp(key, 'gi'), params[key]),
    texts[textKey] || textKey,
  );
};

const Translate = ({className, withWrapper, textKey, params}) => {
  const texts = useSelector((state) => state?.i18n?.texts || {});

  const translation = Object.keys(params).reduce(
    (res, key) => res.replace(new RegExp(key, 'gi'), params[key]),
    texts[textKey] || textKey,
  );
  if (!texts[textKey]) {
    // eslint-disable-next-line no-console
    console.warn('Missing Key for ', textKey);
  }

  return withWrapper ? (
    <span className={classnames('ratchet-translate', className)}>
      {translation}
    </span>
  ) : (
    translation
  );
};

Translate.propTypes = {
  className: PropTypes.string,
  textKey: PropTypes.string.isRequired,
  params: PropTypes.objectOf(PropTypes.any),
  withWrapper: PropTypes.bool,
};

Translate.defaultProps = {
  className: '',
  params: {},
  withWrapper: false,
};

export default React.memo(Translate);

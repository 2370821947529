import {NextApi} from "../Next/next";


export const GET_DEVICE_FEATURES_LIST = 'GET_DEVICE_FEATURES_LIST';


export const getDeviceFeaturesListNext = new NextApi('whitelabel/device/features', GET_DEVICE_FEATURES_LIST, {
  method: 'GET', json: false,
});



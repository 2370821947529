import React from 'react';
import './IensoCheckbox.scss'
import PropTypes from "prop-types";
import CIcon from '@coreui/icons-react';
import iensoIcons from "src/iensoink/ui-components/icons/main";

export const IensoCheckbox =  React.memo(({id, checked, onChange, disabled,label,style}) => {
  return (
    <div className={'ienso-checkbox'} style={style}>
      <input
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        type={'checkbox'}
        className='ienso-checkbox__checkbox-input'
        name={id}
      />
      <CIcon src={iensoIcons.checkboxChecked} className='ienso-checkbox__checkbox--filled'/>
      <div className='ienso-checkbox__checkbox--empty'/>
      <label htmlFor={id} className={'text-body-1'}>{label}</label>
    </div>
  );
})

IensoCheckbox.defaultProps = {
  id: 'checkbox',
  label:'',
  checked: false,
  onChange: () => undefined,
  disabled: false,
  style:null
}
IensoCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

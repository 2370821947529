import React, {useState} from 'react';
import {
  createUrlQueryForExportCsv
} from "../../utils/utils";
import {IensoTableFooter} from "./additional-components/footer/IensoTableFooter";
import {IensoTableHeader} from "./header/IensoTableHeader";
import IensoTableBody from "./body/IensoTableBody";
import classnames from "classnames";

export const TABLE_FILTERS = 'TABLE_FILTERS'
export const IensoTable = ({
                             onClearFilters,
                             options,
                             customFilterSet,
                             customFilter,
                             title,
                             updateDataWithNewQuery,
                             totalItems,
                             exportCsv,
                             exportCsvHandler,
                             defaultFilter,
                             showTitle,
                             showPerPage,
                             showFooter,
                             showHeader,
                             customClassName,
                             showHeaderBottomRow,
                             showFilterBy,
                             query,
                             updateQuery,
                             customChangedFilterFlag,
                             ...props
                           }) => {
  
  //flag for disable/enable 'clearFilter' buttom
  const [changedFilterFlag, setChangedFilterFlag] = useState(customChangedFilterFlag);

  //initial filters value
  const defaultFilters = {
    page: 1,
    limit: 10,
    ...defaultFilter
  }

  const onFilterChange = (value, filter) => {
    let copy = query;

    if (!value) {
      delete copy[filter];

      //delete the filter from query
      updateQuery({...copy});

    }
    else {
      if (filter !== "page" && filter !== "limit") {
        setChangedFilterFlag(true);
        copy.page = 1;
      }
      updateQuery({ ...copy, [filter]: value });
    }
    
  }

  const onSorterChange = (val) => {
    if (val?.column) {
      updateQuery({
        ...query,
        sortBy: val?.column,
        order: val?.asc ? "ASC" : "DESC",
      });
    }
    }

  const resetFilters = () => {
    setChangedFilterFlag(false);
    const newQuery = {...defaultFilters, limit: query?.limit || defaultFilters.limit};

    if (query.search) {
      newQuery.search = query.search;
    }
    updateQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const exportCvsController = () => {
    exportCsvHandler(createUrlQueryForExportCsv(query));
}
  return (
    <div className={classnames('ienso-table', customClassName && customClassName)}>
      {showHeader && <IensoTableHeader 
                                      
                                       customFilter={customFilter}
                                       customFilterSet={customFilterSet}
                                       filterOptions={options}
                                       title={`${title} (${totalItems || 0})`}
                                       onFilterChange={onFilterChange}
                                       headerFilters={query}
                                       exportCsv={exportCsv}
                                       exportCsvHandler={exportCvsController}
                                       resetFilters={resetFilters}
                                       changedFilterFlag={changedFilterFlag || customFilterSet}
                                       showTitle={showTitle}
                                       showPerPage={showPerPage}
                                       showHeaderBottomRow={showHeaderBottomRow}
                                       showFilterBy={showFilterBy}
      />}
      <IensoTableBody onFilterChange={onFilterChange}
                      onSorterChange={onSorterChange}
                      {...props}
      />
      {showFooter && totalItems !== 0 && totalItems && <IensoTableFooter limit={query?.limit}
                                                                         currentPage={Number(query?.page)}
                                                                         totalItems={totalItems}
                                                                         onFilterChange={onFilterChange}
                                                                         itemsAmount={props?.items?.length || 0}
      />}
    </div>
  );
};

IensoTable.defaultProps = {
  exportCsv: false,
  customFilterSet: false,
  showFooter: true,
  showHeader: true,
  showHeaderBottomRow: true,
  updateDataWithNewQuery: () => undefined,
  onClearFilters: () => undefined,
  items: null,
  showFilterBy: true,
  customFilter: null,
  updateQuery: () => undefined,
  showPerPage: false,
  changedFilterFlag: false
}

IensoTable.propTypes = {}

import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {appConfigErrorSelector, realmNameSelector, isLoadingRealmNameSelector, whitelabelInfoSelector} from "../redux/service/realm-info/selectors";
import {getRealmNameOnInitialization} from "../redux/service/realm-info/actions";
import Page500 from "../views/pages/page500/Page500";
import {IensoLoader} from "src/iensoink/ui-components";

const RealmNameWrapper = ({children}) => {
  const realmName = useSelector(realmNameSelector);
  const isError = useSelector(appConfigErrorSelector);
  const isLoading = useSelector(isLoadingRealmNameSelector);
  const whitelabelInfo = useSelector(whitelabelInfoSelector);
  
  useEffect(() => {
    !realmName && getRealmNameOnInitialization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realmName])

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = whitelabelInfo?.faviconUrl || 'http://transparent-favicon.info/favicon.ico';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitelabelInfo])

  return isError ? <Page500/> : isLoading ? <IensoLoader/> : children
};

export default RealmNameWrapper;

import { translationsNext } from './actionTypes';

const defaultState = {
  isLoading: true,
  texts: {},
  languages: {}
};

export const i18n = (state = defaultState, { type, payload }) => {
  switch (type) {
    case translationsNext.INIT:
      return {
        ...state,
        isLoading: true,
      };
    case translationsNext.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case translationsNext.SUCCESS:
      return {
        ...state,
        texts: (payload || {}).translations || {},
        languages: (payload || {}).languages || {},
        isLoading: false,
      };
    default:
      return state;
  }
};

import {
  putOperatorNext,
  postOperatorNext,
  getOperatorByIdNext,
  SET_OPERATOR_ROLE,
  SET_OPERATOR_FIELD,
  SET_OPERATOR_INFO, OPERATORS_SET_SELECTED_TO_NULL
} from './actionType';

export const rolesList = [
  { value: 'default-roles-whitelabela', label: 'default-roles-whitelabela' },
  { value: 'user-management', label: 'user-management' },
  { value: 'user-plan-management', label: 'user-plan-management' },
  { value: 'user-password-management', label: 'user-password-management' },
  { value: 'whitelabel-access-management', label: 'whitelabel-access-management' },
  { value: 'whitelabel-config', label: 'whitelabel-config' },
  { value: 'whitelabel-domain', label: 'whitelabel-domain' }
]

export const scopeList = [
  { value: 'operators-all', label: 'All' },
  { value: 'operators-create', label: 'Create' },
  { value: 'operators-edit', label: 'Edit' },
  { value: 'operators-delete', label: 'Delete' }
]

const initialState = {
  selected:null,
  rolesList,
  scopeList,
  isLoadingGetById: true,
  operatorInfo: {
    firstName: '',
    lastName: '',
    email: '',
    enabled: false,
    operatorRoles: {}
  }
}

export const reducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case OPERATORS_SET_SELECTED_TO_NULL:
      return {...state,...rest}
    case putOperatorNext.INIT:
      return { ...state, isLoading: true }
    case putOperatorNext.SUCCESS:
      return { ...state, isLoading: false }
    case putOperatorNext.ERROR:
      return { ...state, isLoading: false }
    case postOperatorNext.INIT:
      return { ...state, isLoading: true }
    case postOperatorNext.SUCCESS:
      return { ...state, isLoading: false }
    case postOperatorNext.ERROR:
      return { ...state, isLoading: false }

    case getOperatorByIdNext.INIT:
      return { ...state, isLoadingGetById: true }
    case getOperatorByIdNext.SUCCESS:
      return { ...state, isLoadingGetById: false, selected: rest.payload }
    case getOperatorByIdNext.ERROR:
      return state
    case SET_OPERATOR_FIELD:
      return {
        ...state, operatorInfo: {
          ...state.operatorInfo,
          [rest.payload.key]: rest.payload.value
        }
      }
    case SET_OPERATOR_ROLE:
      return {
        ...state,
        operatorInfo: {
          ...state.operatorInfo,
          operatorRoles: {
            ...state.operatorInfo.operatorRoles,
            [rest.payload.roleName]: rest.payload.roleValues
          }
        }
      }
    case SET_OPERATOR_INFO: {
      return {
        ...state,
        operatorInfo: { ...state.operatorInfo, ...rest.payload }
      }
    }
    default:
      return state
  }
}

import { NextApi } from "src/redux/service/Next/next";

export const GET_PACKAGE_BY_ID = "GET_PACKAGE_BY_ID";

export const getPackageByIdNext = new NextApi(
  "whitelabel/packages/{packageId}",
  GET_PACKAGE_BY_ID,
  {
    method: "GET",
    json: true,
  }
);
import React from 'react';
import PropTypes from 'prop-types';
import './IensoInfoCell.scss';
import InfoCellIcon from './InfoCellIcon';

export const IensoInfoCell = React.memo(({ warnings, alerts }) => {
    const renderInfoCellIcons = () => {
        if (warnings || alerts) {
            return (
                <>
                    <InfoCellIcon count={alerts} type={'alert'} />
                    <InfoCellIcon count={warnings} type={'warning'} />
                </>
            )
        }
        return <>-</>
    }
    return (
        <td>
            <div className='info-cell-container'>
                {renderInfoCellIcons()}
            </div>
        </td>
    )
})
IensoInfoCell.defaultProps={
  warnings:null,
  alerts:null
}
IensoInfoCell.propTypes = {
    warnings: PropTypes.number,
    alerts: PropTypes.number,
}


import React from 'react';
import './IensoCombinedLabel.scss';

export const IensoCombinedLabel = ({mainText, secondaryText}) => {
    return (
        <div className='combined-label'>
            <span className='combined-label__main-text text-body-1'>{`${mainText} `}</span>
            <span className='combined-label__secondary-text text-small-1'>{secondaryText}</span>
        </div>
    );
};
import {
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR, getUrlToUploadBrandLogoToS3Next,
} from './actionType';

const initialState = {
  isLoadingUploadImg: false,
  url: '',
  firmwareList: [],
  clearFormFields: false,
  uuid: ''
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getUrlToUploadBrandLogoToS3Next.INIT:
      return { ...state, isLoadingUploadImg: true }
    case getUrlToUploadBrandLogoToS3Next.SUCCESS:
      return { ...state, url: payload.url, uuid: payload.uuid, isLoadingUploadImg: false }
    case getUrlToUploadBrandLogoToS3Next.ERROR:
      return { ...state, url: '', uuid: '', isLoadingUploadImg: false }

//============
    case UPLOAD_FILE_SUCCESS:
      return {...state, url: '', uuid: '', clearFormFields: true}
    case UPLOAD_FILE_ERROR:
      return state;
    default:
      return state
  }
}

export const DashboardIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.13 15.873a.198.198 0 0 1-.118.18.189.189 0 0 1-.21-.042.196.196 0 0 1-.041-.213.195.195 0 0 1 .177-.12c.051 0 .1.02.136.056a.197.197 0 0 1 .057.139zM13.934 17.135h3.53a7.273 7.273 0 0 0 .097 1.816c.136.69.634 1.303 1.375 1.688.205.107.11.36-.139.36H9.068c-.248 0-.344-.253-.136-.36.738-.391 1.238-.998 1.375-1.688a7.32 7.32 0 0 0 .094-1.816h3.533zM3.67 14.6H24.2"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.87 1H2.13A1.14 1.14 0 0 0 1 2.148v13.838a1.14 1.14 0 0 0 1.13 1.148h22.74A1.14 1.14 0 0 0 26 15.986V2.148A1.14 1.14 0 0 0 24.87 1z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.846 11.58V2.172h-9.52l2.854.889a8.526 8.526 0 0 1 3.379 2.004 8.7 8.7 0 0 1 2.146 3.322l1.14 3.193z"
        fill={fill}
      />
    </svg>
  </div>
);

export const MessagingCenterIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m9.887 1.955 14.428 3.337v3.904L9.887 12.533M9.887 7.244V1.672a.642.642 0 0 0-.218-.475A.785.785 0 0 0 9.144 1a.787.787 0 0 0-.526.197.644.644 0 0 0-.22.475v11.144c.001.178.08.349.22.475s.329.197.526.197.386-.071.525-.197a.642.642 0 0 0 .218-.476v-2.203M24.314 5.292l2.644-.38M26.958 9.541l-2.643-.377M26.959 9.368c0 .206.09.405.252.55.162.147.38.229.61.229a.912.912 0 0 0 .607-.229.746.746 0 0 0 .253-.55V5.095a.744.744 0 0 0-.252-.551.938.938 0 0 0-.939-.17.87.87 0 0 0-.28.17.778.778 0 0 0-.186.252.713.713 0 0 0-.065.299v1.02M21.236 9.944V12.4c0 .21-.092.411-.256.56a.924.924 0 0 1-.619.232h-3.34a.954.954 0 0 1-.334-.06.885.885 0 0 1-.284-.172.79.79 0 0 1-.189-.257.725.725 0 0 1-.066-.303v-1.217M5.09 7.493H1M5.696 4.944 2.805 2.328M5.696 9.915l-2.891 2.618M17.384 20.3H14.99a1.37 1.37 0 0 1-.918-.345c-.244-.22-.38-.519-.38-.83v0c0-.312.137-.61.38-.83.244-.22.574-.343.918-.343h5.283M17.796 22.648h-2.019a1.37 1.37 0 0 1-.918-.345c-.244-.22-.38-.519-.38-.83v0M17.796 24.996h-1.158a1.37 1.37 0 0 1-.917-.345 1.121 1.121 0 0 1-.38-.83v0M28.683 25.438c-2.39-.047-2.847.127-2.847.127-.456.088-.908.197-1.352.327-2.342.683-3.995 1.047-6.451 1.108h-.6c-.344 0-.674-.123-.917-.343a1.122 1.122 0 0 1-.381-.83v0M20.273 15.254l.387-.038a5.063 5.063 0 0 1 3.27.702c.607.379 1.295.838 1.927 1.303 0 0 1.27 1.166 2.826 1.166M16.135 25.835v-.84M15.348 23.82v-1.244M31 17.061h-1.583a.774.774 0 0 0-.519.195.634.634 0 0 0-.214.47v8.378c0 .087.018.174.055.254.037.081.09.154.159.216.068.062.149.111.238.145a.8.8 0 0 0 .281.05h1.584M14.488 21.472v-1.265M17.246 17.95v-4.758M20.139 13.192v4.757"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.926 7.244v-.976l-9.201-2.136.231.304a5.09 5.09 0 0 1 1.097 2.838 4.997 4.997 0 0 1-.747 2.93l8.613-1.992.007-.968z"
        fill={fill}
      />
    </svg>
  </div>
);

export const AuditLogIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1188 19.8557C20.119 19.8773 20.1126 19.8984 20.1006 19.9164C20.0886 19.9344 20.0714 19.9485 20.0513 19.9568C20.0312 19.9652 20.009 19.9674 19.9876 19.9633C19.9662 19.9592 19.9465 19.9489 19.931 19.9337C19.9155 19.9185 19.9049 19.8991 19.9006 19.8779C19.8963 19.8568 19.8984 19.8349 19.9068 19.8149C19.9151 19.795 19.9292 19.778 19.9473 19.766C19.9655 19.754 19.9868 19.7476 20.0086 19.7476C20.0377 19.7476 20.0656 19.7589 20.0863 19.7792C20.1069 19.7994 20.1186 19.827 20.1188 19.8557V19.8557Z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0067 17.1782V19.8563L17.875 21.4564"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2148 23.8267C20.3354 24.0879 19.3936 24.0536 18.5357 23.7294C17.6779 23.4052 16.9519 22.809 16.4705 22.0336C15.9892 21.2581 15.7793 20.3467 15.8736 19.4408C15.9679 18.535 16.3609 17.6853 16.9918 17.0237C17.6227 16.3622 18.4561 15.9257 19.3627 15.7822C20.2692 15.6386 21.1982 15.796 22.0053 16.2298C22.8125 16.6636 23.4527 17.3497 23.8266 18.1814C24.2005 19.0131 24.2871 19.9441 24.073 20.8297"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.832 19.8564H16.397"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6211 19.8564H24.1868"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0078 15.7129V16.2733"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0078 23.4399V24.0011"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3619 21.2714H2.37357C2.00962 21.2714 1.66055 21.1281 1.40302 20.873C1.14548 20.6178 1.00053 20.2718 1 19.9107V2.36063C1.00053 1.99959 1.14548 1.65351 1.40302 1.3984C1.66055 1.14329 2.00962 1 2.37357 1H24.6248C24.9887 1 25.3378 1.14329 25.5953 1.3984C25.8529 1.65351 25.9978 1.99959 25.9984 2.36063V17.7199"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.35982 3.679C4.36021 3.63251 4.34668 3.58693 4.32092 3.54808C4.29517 3.50924 4.25836 3.47887 4.21517 3.46081C4.17198 3.44275 4.12435 3.43783 4.07834 3.44666C4.03232 3.45549 3.99 3.47766 3.95672 3.51039C3.92343 3.54313 3.90071 3.58493 3.89142 3.6305C3.88213 3.67607 3.88668 3.72336 3.90452 3.76635C3.92236 3.80934 3.95269 3.84609 3.99163 3.87196C4.03058 3.89783 4.07638 3.91165 4.12325 3.91165C4.15422 3.91191 4.18495 3.90609 4.21364 3.89451C4.24233 3.88294 4.26843 3.86584 4.29042 3.84421C4.31241 3.82258 4.32985 3.79685 4.34177 3.76849C4.35368 3.74013 4.35982 3.70972 4.35982 3.679V3.679Z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.03942 3.67701C7.03942 3.63099 7.02567 3.586 6.9999 3.54773C6.97412 3.50947 6.93749 3.47966 6.89463 3.46205C6.85177 3.44444 6.80461 3.43983 6.75911 3.44881C6.71361 3.45778 6.67181 3.47993 6.639 3.51247C6.6062 3.54501 6.58387 3.58648 6.57482 3.63162C6.56577 3.67675 6.57041 3.72353 6.58817 3.76604C6.60592 3.80856 6.63598 3.84489 6.67455 3.87046C6.71312 3.89602 6.75846 3.90967 6.80485 3.90967C6.83566 3.90967 6.86618 3.90364 6.89463 3.89195C6.92309 3.88026 6.94895 3.86313 6.97073 3.84153C6.99251 3.81992 7.00978 3.79427 7.02157 3.76604C7.03335 3.73782 7.03942 3.70757 7.03942 3.67701V3.67701Z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72298 3.67701C9.72298 3.63099 9.70923 3.586 9.68345 3.54773C9.65768 3.50947 9.62106 3.47966 9.5782 3.46205C9.53534 3.44444 9.48819 3.43983 9.44269 3.44881C9.3972 3.45778 9.35539 3.47993 9.32259 3.51247C9.28979 3.54501 9.26746 3.58648 9.25841 3.63162C9.24936 3.67675 9.25401 3.72353 9.27176 3.76604C9.28951 3.80856 9.31956 3.84489 9.35813 3.87046C9.3967 3.89602 9.44204 3.90967 9.48843 3.90967C9.51923 3.90967 9.54975 3.90364 9.5782 3.89195C9.60666 3.88026 9.63251 3.86313 9.65429 3.84153C9.67607 3.81992 9.69333 3.79427 9.70512 3.76604C9.71691 3.73782 9.72298 3.70757 9.72298 3.67701V3.67701Z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00391 6.40967H26.0003"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.625 3.70361H23.5818"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.92188 11.525V9.46631H11.231V11.525"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42188 13.8701H10.731V14.2662H4.42188V13.8701Z"
        fill="#768192"
        stroke={border}
      />
      <path
        d="M4.42188 16.9341H10.731V17.3302H4.42188V16.9341Z"
        fill="#768192"
        stroke={border}
      />
      <path
        d="M24.5193 17.4081V8H15L17.8539 8.88865C19.1174 9.28257 20.2738 9.9685 21.2324 10.8926C22.191 11.8167 22.9257 12.9538 23.3789 14.2147L24.5193 17.4081Z"
        fill={fill}
      />
    </svg>
  </div>
);

export const ClientManagementIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m11.591 15.954 1.203 2.522c.053.11.13.205.226.277.096.072.208.12.325.137l2.69.404c.018.003.035.01.05.023a.108.108 0 0 1 .005.154l-1.946 1.965a.783.783 0 0 0-.21.67l.459 2.773c.003.019 0 .039-.006.057a.104.104 0 0 1-.035.044.1.1 0 0 1-.105.008L11.84 23.68a.712.712 0 0 0-.68 0l-2.406 1.308a.098.098 0 0 1-.106-.008.104.104 0 0 1-.04-.101l.46-2.772a.783.783 0 0 0-.212-.67l-1.946-1.965a.105.105 0 0 1-.025-.107.104.104 0 0 1 .03-.048.1.1 0 0 1 .051-.023l2.69-.404a.724.724 0 0 0 .325-.137.757.757 0 0 0 .226-.277l1.202-2.522a.104.104 0 0 1 .038-.042.1.1 0 0 1 .143.042z"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.28 11.759s3.742 1.985 5.2 3.129c.346.274.642.603.876.972a4.25 4.25 0 0 1 .643 2.297v3.533H15.48M8.56 11.758s-4.21 1.986-5.851 3.13a4.128 4.128 0 0 0-.985.972A3.904 3.904 0 0 0 1 18.157v3.533h7.334"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.92 13.414c2.784 0 5.04-2.78 5.04-6.207C16.96 3.779 14.705 1 11.92 1 9.138 1 6.88 3.779 6.88 7.207s2.257 6.207 5.04 6.207z"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.305 22.599a2.508 2.508 0 0 0-.056-.972 2.154 2.154 0 0 0-.413-.827l-.436-.525.602-.105c.262-.048.51-.174.725-.367.214-.194.387-.45.504-.745l.27-.68.269.68c.116.295.29.551.503.745.214.194.463.32.725.367l.602.105-.436.525c-.19.23-.331.514-.413.827-.082.314-.1.647-.056.972l.103.745-.538-.352a1.384 1.384 0 0 0-.76-.233c-.264 0-.525.08-.759.233l-.538.352.102-.745z"
        fill={fill}
      />
    </svg>
  </div>
);

export const AnalyticsReportIcon = ({ fill ,border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.709 19.576v-7.014h.763v7.014h-.763z" stroke={fill} />
      <path d="M13.77 15.744h-1.762v4.333h1.763v-4.333z" fill={border} />
      <path d="M17.303 19.576v-7.014h.763v7.014h-.763z" stroke={fill} />
      <path d="M23.365 15.125h-1.763v4.951h1.763v-4.951z" fill={border} />
      <path
        d="m8.092 9.146 4.795 3.682 4.798-3.682 4.796 3.063"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.999 26.18H2.819V1"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 2.82 2.82 1l1.818 1.82M26.18 24.361l1.819 1.82-1.82 1.82M2.818 5.902H4.98M2.818 9.146H4.98M2.818 12.39H4.98M2.818 15.634H4.98M2.818 18.876H4.98M2.818 22.12H4.98M23.172 24.019v2.162M19.928 24.019v2.162M16.684 24.019v2.162M13.44 24.019v2.162M10.197 24.019v2.162M6.951 24.019v2.162"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export const OperatorManagementIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="22"
      height="29"
      viewBox="0 0 22 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1v2.932l6.396 7.328L11 7.67 1 1z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 1v2.932l-6.397 7.328-3.605-3.59L20.999 1zM1 1h20"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.535 10.194 11 12.718l-2.534-2.524"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12.257 11.467 2.507 12.673L10.999 28l-3.765-3.86 2.51-12.673"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m11.057 26.617 2.643-2.664-.777-3.86-1.866 1.791s-.763-.781-1.848-1.884l-.795 3.962 2.643 2.655z"
        fill={fill}
      />
    </svg>
  </div>
);

export const PackageManagementIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m24.199 23.55 1.593-1.624-6.802-1.858L20.813 27l1.592-1.622M23.723 24.893l1.99 2.028M12.444 11.175 4.768 6.659 14.499 1l9.732 5.659-7.696 4.528"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m18.221 21.646-3.722 2.19-9.731-5.726v-5.056M24.231 13.202v4.908l-2.438 1.436M14.5 14.846v5.145"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.232 6.659 28 8.875l-9.732 5.726-3.768-2.217M4.768 6.659 1 8.875l9.732 5.726 3.768-2.217"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m18.06 7.726 1.54-.97-5-3.107-5 3.108 1.54.969a5.546 5.546 0 0 1 3.46-1.231c1.244 0 2.455.43 3.46 1.23z"
        fill={fill}
      />
    </svg>
  </div>
);

export const SoftwareManagementIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.38 27h12.144c.825 0 1.474-.654 1.474-1.485V11.27c0-.593-.472-1.128-1.12-1.128H10.602c-.589 0-1.12.475-1.12 1.128v14.246c0 .831.649 1.484 1.474 1.484h5.424z"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.31 12.398c0-.12-.059-.178-.176-.178-.118 0-.177.059-.177.178 0 .118.059.178.177.178.059 0 .176-.12.176-.178zM14.496 12.398c0-.12-.06-.178-.177-.178-.118 0-.176.059-.176.178 0 .118.058.178.176.178.059 0 .177-.12.177-.178zM16.675 12.398c0-.12-.058-.178-.176-.178s-.177.059-.177.178c0 .118.06.178.177.178.118 0 .176-.12.176-.178zM9.547 14.534h20.456M22.934 22.367l1.827-1.84-1.827-1.84M16.689 22.368l-1.828-1.84 1.828-1.84M21.108 17.739l-2.653 5.52"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.665 15.068c-2.712 0-4.894-2.196-4.894-4.927 0-2.73 2.182-4.926 4.894-4.926 2.711 0 4.893 2.196 4.893 4.926"
        stroke={border}
        strokeMiterlimit="10"
      />
      <path
        d="M16.8 10.141c0-1.068.353-2.137 1.12-2.908l.353-.357-1.533-2.67-.471.118a4.103 4.103 0 0 1-3.007-.475h-.059a3.967 3.967 0 0 1-1.886-2.374L11.199 1H8.133l-.118.475c-.294 1.009-1.002 1.9-1.886 2.374-1.002.594-2.063.772-3.066.534l-.53-.118L1 6.935l.354.357a4.217 4.217 0 0 1 1.12 2.85v.059c0 1.068-.354 2.077-1.12 2.849L1 13.406l1.533 2.671.471-.119a4.103 4.103 0 0 1 3.007.475h.059a3.967 3.967 0 0 1 1.886 2.375l.118.474H9.49"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.012 10.14c0 .95.353 1.841 1.002 2.494v-.238a5.37 5.37 0 0 1 4.657-5.342c-.59-.356-1.238-.593-1.945-.593-2.064 0-3.714 1.662-3.714 3.68z"
        fill={fill}
      />
    </svg>
  </div>
);

export const DeviceManagementIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m23.792 5.994 1.285 3.121-18.261 8.046-1.15-2.705M20.676 6.896l-12.445 5.48a4.086 4.086 0 0 1-5.41-2.22l-.135-.347L22.705 1l.135.347c.88 2.15-.067 4.647-2.164 5.549z"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m3.705 11.473-1.826.832.677 1.526-.203-.485L1 13.97l1.15 2.705 1.352-.624-.27-.624"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m6.35 16.121-2.435 1.04-.474-1.11M8.371 9.116l9.942-4.44M20.34 20.699c0-1.11-.88-2.08-2.03-2.08-1.082 0-2.029.9-2.029 2.08 0 1.11.88 2.08 2.03 2.08 1.149 0 2.028-.9 2.028-2.08zM27.107 19.658H21.63M21.629 21.74h5.478M14.26 14.108l1.826 4.023M17.843 17.23l-1.759-4.023M29 23.888h-1.895v-6.381H29M29 25v-8.6"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fill={fill} d="m8.293 13.432 12.968-5.646.695 1.678L8.987 15.11z" />
    </svg>
  </div>
);

export const SettingsIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.621 15.73v10.348c0 .245.098.479.273.652.175.173.412.27.659.27h7.402a.936.936 0 0 0 .659-.27.917.917 0 0 0 .272-.652V15.73"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.331 13.91H17.18a.665.665 0 0 0-.668.661v.499c0 .365.299.661.668.661h10.151c.37 0 .669-.296.669-.661v-.499c0-.365-.3-.66-.669-.66z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.508 13.911a3.1 3.1 0 0 1 .922-2.204 3.167 3.167 0 0 1 2.228-.913h1.203a3.178 3.178 0 0 1 2.23.912 3.116 3.116 0 0 1 .924 2.205v0"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.406 10.796V7.925h1.699v2.87M21.405 9.124h-2.784M4.99 6.296h14.394a.751.751 0 0 0 .471-.164.738.738 0 0 0 .261-.422c.29-1.36.29-2.766 0-4.126a.735.735 0 0 0-.261-.42.75.75 0 0 0-.471-.164H4.99a.75.75 0 0 0-.47.164.735.735 0 0 0-.26.42 9.896 9.896 0 0 0 0 4.126.737.737 0 0 0 .26.421.751.751 0 0 0 .47.165v0zM12.184 24.282a1.851 1.851 0 0 0 1.298-.532 1.815 1.815 0 0 0 .536-1.285V15.1a1.8 1.8 0 0 0-.537-1.285 1.837 1.837 0 0 0-1.297-.532v0c-.487 0-.954.192-1.298.532-.345.341-.538.803-.538 1.285v7.364c0 .482.193.944.537 1.285.345.34.812.532 1.299.532z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.043 3.652H1v3.1c0 .762.306 1.493.851 2.032.545.54 1.284.842 2.054.842h6.58c.45 0 .883.177 1.201.493.319.315.498.742.498 1.188v1.97"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.176 3.176a2.11 2.11 0 0 1 .273-1H6.691a2.191 2.191 0 0 0 0 2h11.737a2.145 2.145 0 0 1-.252-1z"
        fill={fill}
      />
      <path d="m25.656 20.895-6.183 4.887h6.183v-4.887z" fill={border} />
    </svg>
  </div>
);

export const SidebarToggleIcon = ({ fill, border }) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="31" height="31" rx="15.5" fill="#ffffff" />
    <path d="M9 21h13H9z" fill="#fff" />
    <path
      d="M9 11h13M9 16h13M9 21h13"
      stroke={fill}
      strokeWidth="2.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="i4374zsoja"
        x1="15.5"
        y1="0"
        x2="15.5"
        y2="31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAFAFA" />
        <stop offset="1" stopColor="#FDFDFD" />
      </linearGradient>
    </defs>
  </svg>
);

export const SecurityInsightsIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="27"
      height="22"
      viewBox="0 0 32 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m17.07 10.188 3.298-4.456M14.355 8.637l1.328 1.572M11.449 10.372l1.485-1.735M8.834 8.672l1.27 1.68M6.066 10.413 7.39 8.619M9.164 7.77c0-.215-.063-.425-.18-.604a1.072 1.072 0 0 0-.479-.401A1.046 1.046 0 0 0 7.343 7a1.11 1.11 0 0 0-.231 1.187c.08.199.217.369.393.488a1.05 1.05 0 0 0 1.346-.135 1.1 1.1 0 0 0 .313-.77zM11.851 11.087c0-.215-.063-.426-.18-.605a1.072 1.072 0 0 0-.478-.401 1.046 1.046 0 0 0-1.162.236 1.11 1.11 0 0 0-.231 1.187c.08.199.217.369.392.489a1.051 1.051 0 0 0 1.347-.136 1.091 1.091 0 0 0 .312-.77zM14.72 7.77c0-.215-.063-.425-.18-.604a1.071 1.071 0 0 0-.479-.401A1.046 1.046 0 0 0 12.9 7a1.111 1.111 0 0 0-.231 1.187c.08.199.218.369.393.488a1.05 1.05 0 0 0 1.346-.135 1.1 1.1 0 0 0 .313-.77zM17.446 10.873c0-.216-.062-.427-.179-.607a1.074 1.074 0 0 0-.479-.403 1.048 1.048 0 0 0-1.165.235 1.113 1.113 0 0 0-.233 1.19c.081.2.218.37.394.49a1.053 1.053 0 0 0 1.35-.134 1.093 1.093 0 0 0 .312-.771zM22.118 5.04c0-.215-.063-.426-.18-.605a1.072 1.072 0 0 0-.479-.4 1.046 1.046 0 0 0-1.162.235 1.11 1.11 0 0 0-.231 1.187c.08.199.217.37.393.489a1.05 1.05 0 0 0 1.346-.136 1.1 1.1 0 0 0 .313-.77zM6.468 11.087c0-.215-.062-.426-.18-.605a1.072 1.072 0 0 0-.478-.401 1.046 1.046 0 0 0-1.162.236 1.11 1.11 0 0 0-.231 1.187c.08.199.217.369.392.489a1.051 1.051 0 0 0 1.347-.136 1.1 1.1 0 0 0 .312-.77z"
        stroke={border}
        strokeMiterlimit="10"
      />
      <path
        d="M13.255 18.07h3.574a7.433 7.433 0 0 0 .096 1.848c.09.374.262.723.502 1.02.24.297.544.535.887.696v0c.211.11.113.366-.138.366H8.334c-.249 0-.347-.257-.138-.366a2.52 2.52 0 0 0 .888-.696c.24-.297.413-.645.504-1.02.108-.61.14-1.23.095-1.848h3.572zM13.488 16.567a.25.25 0 0 1-.147.228.236.236 0 0 1-.262-.052.246.246 0 0 1-.053-.267.244.244 0 0 1 .222-.152c.063 0 .124.026.169.071.045.046.07.108.07.172v0z"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 18.07H2.21c-.321 0-.629-.13-.855-.362A1.25 1.25 0 0 1 1 16.836v-1.762h4.739"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.226 15.074H1V2.334a1.364 1.364 0 0 1 .387-.945A1.31 1.31 0 0 1 2.316 1h21.88c.347 0 .68.14.926.391s.384.591.385.946v6.215"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.937 10.004c-3.39.389-5.494-1.512-5.494-1.512s-2.103 1.9-5.493 1.512c0 0-1.058 8.76 5.494 10.649 6.55-1.89 5.493-10.649 5.493-10.649z"
        fill={fill}
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="m23.282 14.553 1.904 1.657 2.747-3.278"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </div>
);

export const AnalyticsManagementIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.709 12.561h.763v7.015h-.763v-7.015z" stroke={fill} />
      <path d="M13.77 15.744h-1.762v4.333h1.763v-4.333z" fill={border} />
      <path d="M17.303 12.561h.763v7.015h-.763v-7.015z" stroke={fill} />
      <path d="M23.365 15.125h-1.763v4.951h1.763v-4.951z" fill={border} />
      <path
        d="m8.094 9.146 4.795 3.682 4.798-3.682 4.796 3.063"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.999 26.18H2.819V1"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 2.82 2.82 1l1.818 1.82M26.18 24.361l1.819 1.82-1.82 1.82M2.818 5.902H4.98M2.818 9.146H4.98M2.818 12.39H4.98M2.818 15.634H4.98M2.818 18.876H4.98M2.818 22.12H4.98M23.172 24.019v2.162M19.926 24.019v2.162M16.684 24.019v2.162M13.438 24.019v2.162M10.197 24.019v2.162M6.951 24.019v2.162"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export const DeviceDatabaseIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="32"
      height="27"
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 16.097v1.242a1 1 0 0 1-.23.633 1.113 1.113 0 0 1-.591.371v0a25.848 25.848 0 0 1-12.55 0 1.112 1.112 0 0 1-.591-.371 1 1 0 0 1-.23-.633v-1.242"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.654 15.863v.764c0 .141-.062.278-.175.39a.899.899 0 0 1-.453.228 24.51 24.51 0 0 1-9.612 0 .9.9 0 0 1-.454-.228.548.548 0 0 1-.175-.39v-.764"
        fill={fill}
      />
      <path
        d="M31 19.54v1.243c0 .23-.081.452-.23.633a1.11 1.11 0 0 1-.591.371v0a25.85 25.85 0 0 1-12.55 0v0a1.11 1.11 0 0 1-.592-.37.999.999 0 0 1-.228-.634v-1.242"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.654 19.579v.765c0 .14-.061.278-.175.39a.9.9 0 0 1-.453.227 24.53 24.53 0 0 1-9.612 0 .9.9 0 0 1-.454-.228.546.546 0 0 1-.175-.39v-.763"
        fill={fill}
      />
      <path
        d="M31 22.98v1.243c0 .229-.081.451-.23.633a1.11 1.11 0 0 1-.591.37 25.848 25.848 0 0 1-12.55 0v0a1.11 1.11 0 0 1-.592-.37.999.999 0 0 1-.228-.633V22.98"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.654 23.295v.764c0 .14-.061.278-.175.39a.898.898 0 0 1-.453.228 24.515 24.515 0 0 1-9.612 0 .898.898 0 0 1-.454-.229.546.546 0 0 1-.175-.39v-.763"
        fill={fill}
      />
      <path
        d="M27.06 12.147c2.334.308 3.94.95 3.94 1.692 0 1.043-3.178 1.89-7.096 1.89s-7.095-.852-7.095-1.89c0-.741 1.603-1.384 3.936-1.692"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.489 13.076V2.491c0-.396-.167-.775-.465-1.054-.297-.28-.7-.437-1.12-.437H2.583c-.208 0-.414.039-.607.114a1.594 1.594 0 0 0-.514.323 1.487 1.487 0 0 0-.343.483c-.08.181-.12.375-.12.57v19.33c0 .492.208.964.578 1.311.37.348.871.544 1.394.544H16.81"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.962 3.991a.25.25 0 0 0-.046-.146.275.275 0 0 0-.124-.097.294.294 0 0 0-.304.056.257.257 0 0 0-.076.134.247.247 0 0 0 .015.15.265.265 0 0 0 .103.118.29.29 0 0 0 .35-.031.254.254 0 0 0 .082-.184v0zM8.126 3.991a.25.25 0 0 0-.046-.146.275.275 0 0 0-.124-.097.294.294 0 0 0-.304.056.247.247 0 0 0-.061.285.265.265 0 0 0 .103.117.29.29 0 0 0 .35-.031.254.254 0 0 0 .082-.184v0zM11.29 3.991a.25.25 0 0 0-.046-.146.274.274 0 0 0-.124-.097.294.294 0 0 0-.303.056.256.256 0 0 0-.077.134.247.247 0 0 0 .015.15c.021.049.057.09.103.118a.29.29 0 0 0 .35-.031.254.254 0 0 0 .082-.184v0zM1 6.934h29.49"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export const CampainManagementIcon = ({ fill, border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.617 21.451c4.213-1.717 6.214-6.47 4.469-10.615-1.745-4.145-6.576-6.114-10.79-4.396-4.212 1.717-6.213 6.47-4.468 10.614 1.745 4.146 6.576 6.114 10.789 4.397z"
        stroke={border}
        strokeMiterlimit="10"
      />
      <path
        d="m25.455 9.878.515-.53-2.21-3.77-.724.176a5.94 5.94 0 0 1-4.36-.642h-.041a5.822 5.822 0 0 1-2.767-3.405L15.665 1h-4.401l-.204.707a5.815 5.815 0 0 1-2.755 3.4 5.94 5.94 0 0 1-4.37.647l-.725-.176L1 9.348l.515.53a5.747 5.747 0 0 1 1.617 4.054v.047a5.723 5.723 0 0 1-1.617 4.041L1 18.545l2.21 3.77.724-.177a5.958 5.958 0 0 1 4.36.643h.047a5.833 5.833 0 0 1 2.76 3.405l.204.707h4.425l.204-.707a5.804 5.804 0 0 1 2.76-3.4v0a5.97 5.97 0 0 1 4.372-.648l.724.177 2.21-3.77-.515-.525a5.763 5.763 0 0 1-1.617-4.059v-.047a5.733 5.733 0 0 1 1.587-4.036v0z"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.538 11.557v4.666l-4.108 2.333-4.108-2.333v-4.666"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path d="m9.322 11.555 4.108-2.333 4.108 2.333-4.108 2.333" fill={fill} />
      <path
        d="m9.322 11.555 4.108-2.333 4.108 2.333-4.108 2.333"
        stroke={border}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.435 13.892v3.099"
        stroke={border}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </div>
);

export const IntegrationsIcon = ({ border }) => (
  <div className={"navigation-icon-wrapper"}>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.238 5.929h3.833m3.834 0H19.07m0 0V2.096m0 3.833v3.833" stroke={border} stroke-linecap="round" stroke-linejoin="round" />
      <rect x="1" y="1" width="9.857" height="9.857" rx="2" stroke={border} />
      <rect x="1" y="14.143" width="9.857" height="9.857" rx="2" stroke={border} />
      <rect x="14.143" y="14.143" width="9.857" height="9.857" rx="2" stroke={border} />
    </svg>
  </div>
);
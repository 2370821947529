import {
  addNewDeviceNext,
  getDevicesNext,
  SELECT_DEVICE,
  EXPORT_FILE_SUCCESS,
  EXPORT_FILE_INIT,
  EXPORT_FILE_ERROR,
} from "./actionType";

const initialState = {
  isLoading: true,
  dataList: null,
  totalItemsInList: 0,
  isLoadingAddDevice: false,
  selectedItem: null,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getDevicesNext.INIT:
      return { ...state, dataList: null, isLoading: true };
    case getDevicesNext.SUCCESS:
      return {
        ...state,
        dataList: (payload?.list || []).map(({ _id, ...rest }) => ({
          serialNumber: rest?.serialNumber,
          model: rest?.connectedDevice?.deviceTypeName,
          modelNumber: rest?.connectedDevice?.deviceTypeId,
          onlineStatus: rest?.connectedDevice?.status || "N/A",
          firmware: rest?.connectedDevice?.firmware,
          firmwareUpdated: rest?.connectedDevice?.firmwareLastUpdated,
          lastOnline: rest?.connectedDevice?.lastLiveStream,
          warnings: rest?.connectedDevice?.numOfWarnings,
          errors: rest?.connectedDevice?.numOfErrors,
          ...rest,
        })),
        totalItemsInList: payload?.totalItems,
        isLoading: false,
      };
    case getDevicesNext.ERROR:
      return { ...state, isLoading: false };

    case addNewDeviceNext.INIT:
      return { ...state, isLoadingAddDevice: true };
    case addNewDeviceNext.SUCCESS:
      return { ...state, isLoadingAddDevice: false };
    case addNewDeviceNext.ERROR:
      return { ...state, isLoadingAddDevice: false };

    case SELECT_DEVICE:
      return { ...state, selectedItem: payload };
    case EXPORT_FILE_INIT:
      return { ...state, isLoading: true };
    case EXPORT_FILE_SUCCESS:
      return { ...state, isLoading: false };
    case EXPORT_FILE_ERROR:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

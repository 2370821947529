import { NextApi } from '../../../../redux/service/Next/next';

export const OPERATORS_DELETE = 'OPERATORS_DELETE'
export const SELECT_OPERATOR = 'SELECT_OPERATOR'
const OPERATORS_API_URL_PATH = '/whitelabel/operators'
export const SET_OPERATOR_INFO = 'SET_OPERATOR_INFO';

export const deleteOperatorNext = new NextApi(`${OPERATORS_API_URL_PATH}/{operatorId}`, OPERATORS_DELETE, {
  method: 'DELETE', json: false,
});



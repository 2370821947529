import {NextApi} from '../../../redux/service/Next/next';

export const GET_URL_TO_UPLOAD_BRAND_LOGO_TO_S3 = 'GET_URL_TO_UPLOAD_BRAND_LOGO_TO_S3';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const WHITELABEL_SETTINGS_API_COMPLETE_UPLOAD = 'WHITELABEL_SETTINGS_API_COMPLETE_UPLOAD'

const WHITELABEL_API_URL_PATH = '/whitelabel/whitelabel-settings/get-url';
const WHITELABEL_SETTINGS_API_COMPLETE_UPLOAD_PATH = '/whitelabel/whitelabel-settings/complete-upload';

export const getUrlToUploadBrandLogoToS3Next = new NextApi(WHITELABEL_API_URL_PATH, GET_URL_TO_UPLOAD_BRAND_LOGO_TO_S3, {
  method: 'POST', json: true,
});

export const uploadSettingsToS3Next = new NextApi(WHITELABEL_SETTINGS_API_COMPLETE_UPLOAD_PATH, WHITELABEL_SETTINGS_API_COMPLETE_UPLOAD, {
  method: 'PUT', json: true,
})


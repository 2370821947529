import {NextApi} from "../../../redux/service/Next/next";

export const GET_DEVICE_BY_ID = 'GET_DEVICE_BY_ID'
export const DELETE_DEVICE_BY_ID = 'DELETE_DEVICE_BY_ID'
export const UPDATE_DEVICE_BY_ID = 'UPDATE_DEVICE_BY_ID'



export const getDeviceByIdNext = new NextApi("device-manager/operator/{deviceId}", GET_DEVICE_BY_ID, {
  method: "GET", json: false,
});

export const deleteDeviceByIdNext = new NextApi("device-manager/operator/{deviceId}", DELETE_DEVICE_BY_ID, {
  method: "DELETE", json: false,
});

export const updateDeviceByIdNext = new NextApi("device-manager/operator/{deviceId}", UPDATE_DEVICE_BY_ID, {
  method: "PATCH", json: false,
});

import {NextApi} from "../Next/next";


export const GET_FIRMWARE_LIST = 'GET_FIRMWARE_LIST';
export const GET_FILTERED_FIRMWARE_LIST = 'GET_FILTERED_FIRMWARE_LIST';

export const getFirmwareListNext = new NextApi('whitelabel/version-management/get-versions', GET_FIRMWARE_LIST, {
  method: 'GET', json: false,
});

export const getFilteredFirmwareListNext = new NextApi('whitelabel/version-management/get-filtered-firmware-list', GET_FILTERED_FIRMWARE_LIST, {
  method: 'GET', json: false,
});

import { NextApi } from 'src/redux/service/Next/next';

export const DEVICE_EVENTS = 'DEVICE_EVENTS';
export const MODAL_DEVICE_EVENTS = 'MODAL_DEVICE_EVENTS';

export const getDeviceEventsNext = new NextApi(`analytics/device-events`, DEVICE_EVENTS, {
  method: 'GET', json: false,
});

export const getModalDeviceEventsNext = new NextApi(`analytics/device-events`, MODAL_DEVICE_EVENTS, {
  method: 'GET', json: false,
});

import React from 'react';
import './IensoInfoCell.scss';
import CIcon from "@coreui/icons-react";
import iensoIcons from "src/iensoink/ui-components/icons/main";
import {IensoTooltip} from "../../../IensoTooltip/IensoTooltip";

const InfoCellIcon = React.memo(({ type, count }) => {
    const renderIcon = () => {
        const hidden = !count || Number(count) === 0;
        const icons = {
          alert: <CIcon className={`info-cell-container__${type}__icon`} src={iensoIcons.alertIcon} />,
            warning: <CIcon className={`info-cell-container__${type}__icon`} src={iensoIcons.warningIcon} />,
        }
        return <IensoTooltip text={`Text about ${type}`}><span className={`info-cell-container__${type}${hidden ? '__hidden': ''} text-small-1`}>{count > 9 ? `${9}+` : count}{icons[type]}</span></IensoTooltip>
    }
    return renderIcon();
})

export default InfoCellIcon;

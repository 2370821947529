import {NextApi} from "../../../redux/service/Next/next";

export const GET_DASHBOARD_HEADER_DATA = 'GET_DASHBOARD_HEADER_DATA'
export const GET_LATEST_UPDATES = 'GET_LATEST_UPDATES'
export const GET_CLOUD_USAGE = 'GET_CLOUD_USAGE'
export const GET_DEVICE_MODELS_COUNT = 'GET_DEVICE_MODELS_COUNT'
export const GET_USER_REGIONS = 'GET_USER_REGIONS'
export const GET_FIRMWARE_USAGE = 'GET_FIRMWARE_USAGE'
export const GET_DASHBOARD_MAIN_DATA = 'GET_DASHBOARD_MAIN_DATA'
export const SELECT_MSG = 'SELECT_MSG'
export const GET_DEVICE_LOGS_INFO = "GET_DEVICE_LOGS_INFO"

const DASHBOARD_HEADER_DATA_API_URL_PATH = 'user-info/operator/info-dashboard'
const LATEST_UPDATES_API_URL_PATH = 'whitelabel/messages/last'

export const getUsersDevicesSpacesDataNext = new NextApi(DASHBOARD_HEADER_DATA_API_URL_PATH, GET_DASHBOARD_HEADER_DATA, {
  method: 'GET', json: false,
});

export const getLatestUpdatesNext = new NextApi(LATEST_UPDATES_API_URL_PATH, GET_LATEST_UPDATES, {
  method: 'GET', json: false,
});
export const getCloudUsageNext = new NextApi('whitelabel/cloud-usage', GET_CLOUD_USAGE, {
  method: 'GET', json: false,
});

export const getDeviceModelsNext = new NextApi('device-manager/operator/get-num-of-every-type', GET_DEVICE_MODELS_COUNT, {
  method: 'GET', json: false,
});

export const getUserRegionsNext = new NextApi('whitelabel/user-regions', GET_USER_REGIONS, {
  method: 'GET', json: false,
});

export const getFirmwareUsageNext = new NextApi('/whitelabel/version-management/get-filtered-firmware-list', GET_FIRMWARE_USAGE, {
  method: 'GET', json: false,
});


export const getDeviceLogsInfoNext = new NextApi('/device-manager/operator/alarms', GET_DEVICE_LOGS_INFO, {
  method: 'GET', json: false,
});

import React, {useEffect, useState} from 'react';
import './Search.scss'
import {
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
} from "@coreui/react";
import classnames from "classnames";
import useDebounce from "src/iensoink/ui-components/utils/useDebounce";
import {BsSearch} from "react-icons/all";
import { translate } from 'src/redux/service/i18n';

export const Search = ({
  className,
  customSearchPlaceHolder,
  query,
  updateQuery,
  ...props
}) => {
  const [flagAfterFirstChange, setFlagAfterFirstChange] = useState(false);
  const [val, setVal] = useState(query?.search || "");
  const deferredValue = useDebounce(val, 1000);

  useEffect(() => {
    const copy = { ...query };
    
    if (deferredValue || (!deferredValue && flagAfterFirstChange)) {
      copy.search = deferredValue;
      setFlagAfterFirstChange(true);
    }

    if (copy.page){
      copy.page = 1;
    }

    updateQuery({ ...copy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredValue]);

  return (
    <div className={classnames("table-search", className)}>
      <div className={"ienso-input-group"}>
        <CFormGroup>
          <CInputGroup>
            <CInputGroupPrepend>
              <CInputGroupText className={"text-white"}>
                <div className={"text-field-icon"}>
                  <BsSearch />
                </div>
              </CInputGroupText>
            </CInputGroupPrepend>
            <CInput
              value={val}
              placeholder={translate(customSearchPlaceHolder)}
              onChange={(e) => setVal(e.target.value)}
              {...props}
            />
          </CInputGroup>
        </CFormGroup>
      </div>
    </div>
  );
};


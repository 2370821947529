import { getSpaceEventsNext, getModalSpaceEventsNext } from './actionType';

const initialState = {
  spaceEvents: null,
  modalSpaceEvents: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getSpaceEventsNext.INIT:
      return { ...state, spaceEvents: null }
    case getSpaceEventsNext.SUCCESS:
      return { ...state, spaceEvents: payload }
    case getModalSpaceEventsNext.INIT:
      return { ...state, modalSpaceEvents: null }
    case getModalSpaceEventsNext.SUCCESS:
      return { ...state, modalSpaceEvents: payload }
    default:
      return state
  }
}

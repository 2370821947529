import React from 'react';
import {
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CLabel
} from "@coreui/react";
import './IensoInput.scss'
import '../IensoInputsGroup.scss'
import PropTypes from "prop-types";
import {useField} from "formik";
import classnames from "classnames";
import { IensoTooltip } from "src/iensoink/ui-components";

export const IensoInput = React.memo(
  ({
    label,
    icon,
    tooltipText,
    tooltipIcon,
    useTooltip,
    id,
    onChange,
    useIcon,
    maxCharsLength,
    setErrors,
    hideErrorText,
    ...props
  }) => {
    
    const [field, meta] = useField(props);

    return (
      <div
        className={classnames(
          "ienso-input-group text-input  ienso-inputs-group",
          props?.disabled && "group-disabled",
          useIcon ? "input-rounded-border-right" : "input-rounded-border"
        )}
        id={id}
      >
        <CFormGroup>
          <CLabel>
            {label}
            {useTooltip && (
              <IensoTooltip text={tooltipText}>{tooltipIcon}</IensoTooltip>
            )}
          </CLabel>
          <CInputGroup>
            {useIcon && (
              <CInputGroupPrepend>
                <CInputGroupText
                  className={classnames(
                    "text-white",
                    props?.invalid && "input-group-text-invalid1"
                  )}
                >
                  <div className={"text-field-icon"}>{icon}</div>
                </CInputGroupText>
              </CInputGroupPrepend>
            )}
            <CInput
              id={id}
              {...field}
              {...props}
              onChange={(e) => {
                const strSize = e.target.value.length;
                if (strSize <= maxCharsLength) {
                  onChange(e);
                }

                if (maxCharsLength && strSize - 1 === maxCharsLength) {
                  setErrors({
                    ...props.errors,
                    [props.name]: `Max size ${maxCharsLength}`,
                  });
                }
              }}
            />
            {!hideErrorText && (
              <CInvalidFeedback>{meta?.error}</CInvalidFeedback>
            )}
          </CInputGroup>
        </CFormGroup>
      </div>
    );
  }
);

IensoInput.propTypes = {
  onChange: PropTypes.func,
  icon: PropTypes.element,
  id: PropTypes.string,
  label: PropTypes.string,
  useIcon: PropTypes.bool,
  hideErrorText: PropTypes.bool
}
IensoInput.defaultProps = {
  onChange: () => undefined,
  icon: null,
  id: '',
  label: '',
  useIcon: true,
  useTooltip: false,
  maxCharsLength: 26,
  hideErrorText: false,
  setErrors: () => undefined,
  errors: {}
}

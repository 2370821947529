import React, {useEffect} from 'react';
import {getAppLangFromLocalStorage} from "../service/utils/utilFunctions";
import {i18n_getTranslations} from "../redux/service/i18n/actions";
import {selectedLanguageSelector} from "../redux/service/app/selectors";
import {useSelector} from "react-redux";
import {isLoadingI18n} from "../redux/service/i18n/selectors";
import {IensoLoader} from "src/iensoink/ui-components";

const LanguageInitWrapper = ({children}) => {
  const appLanguage = useSelector(selectedLanguageSelector)
  const isLoading = useSelector(isLoadingI18n)

  useEffect(() => {
    getAppLangFromLocalStorage()
    i18n_getTranslations(appLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLanguage])

  return isLoading ? <IensoLoader/> : children
};

export default LanguageInitWrapper;

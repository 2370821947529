import { getDeviceManagementEventsNext, getModalDeviceManagementEventsNext } from './actionType';

const initialState = {
  deviceManagementEvents: null,
  modalDeviceManagementEvents: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getDeviceManagementEventsNext.INIT:
      return { ...state, deviceManagementEvents: null }
    case getDeviceManagementEventsNext.SUCCESS:
      return { ...state, deviceManagementEvents: payload }
    case getModalDeviceManagementEventsNext.INIT:
      return { ...state, modalDeviceManagementEvents: null }
    case getModalDeviceManagementEventsNext.SUCCESS:
      return { ...state, modalDeviceManagementEvents: payload }
    default:
      return state
  }
}

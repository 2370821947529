import React, {useEffect, useState} from 'react';
import {
  CDropdownItem,
  CCollapse,
} from '@coreui/react'
import './IensoMultiSelectionCategories.scss'
import {BsChevronDown} from "react-icons/all";
import {IensoCheckbox} from "../../index";
import classnames from "classnames";


export const IensoMultiSelectionCategoriesItem = ({
                                                    item,
                                                    onToggle,
                                                    valuesHash,
                                                    parentCollapsed = false,
                                                    getSubItems
                                                  }) => {
  const {value, label, items} = item;
  const hasSubMenu = items && items.length;
  const [isCollapsed, setCollapsed] = useState(null);
  const [isAutoCollapsed, setAutoCollapsed] = useState(false);
  useEffect(() => {
    if (!parentCollapsed) {
      setCollapsed(false);
      setAutoCollapsed(false);
    }
  }, [parentCollapsed]);

  const onClickHandle = (e, item) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setAutoCollapsed(!valuesHash[item.value]);
    onToggle(item);
  }
  const handleToggleCollapse = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setCollapsed(!(isCollapsed || isAutoCollapsed));
    setAutoCollapsed(false);
  }

  const subItems = getSubItems(item);
  const selectedSubItems = subItems.filter(({value}) => valuesHash[value]).length;

  return (
    <CDropdownItem
      tag="div"
      active={!!valuesHash[value]}
      className={classnames(hasSubMenu && "has-sub-menu")}
    >
      <span className="item-label" onClick={(e) => onClickHandle(e, item)}>
        <IensoCheckbox checked={!!(valuesHash[value] || selectedSubItems)} />
        <span
          className={classnames(
            "dropdown-item-custom-text",
            !!valuesHash[value]
              ? "text-body-1"
              : hasSubMenu && selectedSubItems
              ? "text-body-2"
              : "text-body-3"
          )}
        >
          {label}
        </span>
        {!!hasSubMenu ? (
          <span
            className={classnames(
              "item-expand",
              (isCollapsed || isAutoCollapsed) && "opened-sub-menu"
            )}
            onClick={handleToggleCollapse}
          >
            <BsChevronDown />
          </span>
        ) : (
          <span
            style={{ display: !!hasSubMenu ? "inline" : "none" }}
            className="item-expand"
          >
            &nbsp;
          </span>
        )}
        {hasSubMenu && (
          <>
            <CCollapse show={isCollapsed || isAutoCollapsed}>
              {(items || []).map((item) => (
                <IensoMultiSelectionCategoriesItem
                  key={item?.value}
                  item={item}
                  onToggle={onToggle}
                  valuesHash={valuesHash}
                  parentCollapsed={isCollapsed || isAutoCollapsed}
                  getSubItems={getSubItems}
                />
              ))}
            </CCollapse>
          </>
        )}
      </span>
    </CDropdownItem>
  );
};


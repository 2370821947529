import { NextApi } from "src/redux/service/Next/next";

export const CREATE_PACKAGE = "CREATE_PACKAGE";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const GET_PACKAGE_BY_ID = "GET_PACKAGE_BY_ID";
export const RESET_DATA = "RESET_DATA";
export const SELECT_PACKAGE_ITEM = "SELECT_PACKAGE_ITEM";
export const CREATE_PACKAGE_VALUES = "CREATE_PACKAGE_VALUES";

export const createPackageNext = new NextApi(
  "whitelabel/packages",
  CREATE_PACKAGE,
  {
    method: "POST",
    json: true,
  }
);

export const getPackageByIdNext = new NextApi(
  "whitelabel/packages/{packageId}",
  GET_PACKAGE_BY_ID,
  {
    method: "GET",
    json: true,
  }
);

export const updatePackageNext = new NextApi(
  "whitelabel/packages/{packageId}",
  UPDATE_PACKAGE,
  {
    method: "PUT",
    json: true,
  }
);

import {
  getTokenNext,
  validateTokenNext
} from "./actionTypes";
import moment from "moment";
import {createHttpAction} from "../Next/http-fetch";
import {getCurrentUrl} from "../../../service/utils/utilFunctions";
import {localStorageConfig} from "../../../config";


export const validateTokenAction = () => createHttpAction(validateTokenNext, null).action;


export const getTokenAction = (code, onSuccess) => createHttpAction(getTokenNext, new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: 'operators',//fixme client id
    code,
    redirect_uri: getCurrentUrl()
  }),
  {onSuccess}
).action

export const refreshTokenAction = () => createHttpAction(getTokenNext, new URLSearchParams({
  grant_type: 'refresh_token',
  client_id: 'operators',
  refresh_token: window.localStorage.getItem(localStorageConfig.REFRESH_TOKEN)
}),).action


export const setValuesToLocalStorage = (payload) => {
  const SAFE_PERIOD = 50// in seconds
  if (payload) {
    window.localStorage.setItem(localStorageConfig.AUTH_TOKEN, payload['access_token'])
    window.localStorage.setItem(localStorageConfig.REFRESH_TOKEN, payload['refresh_token'])
    window.localStorage.setItem(localStorageConfig.TOKEN_EXPIRES_IN, moment().add(payload["expires_in"] - SAFE_PERIOD, 'second').toString()) // should be checked before token expires
    window.localStorage.setItem(localStorageConfig.REFRESH_TOKEN_EXPIRES_IN, moment().add(payload["refresh_expires_in"], 'second').toString())
  }
}




import {
  CLIENTS_SELECT_ITEM,
  getClientsNext,
  getDeviceTypesNext,
  getPackageTypesNext,
  EXPORT_FILE_SUCCESS,
  EXPORT_FILE_INIT,
EXPORT_FILE_ERROR
} from "./actionType";

const initialState = {
  selected: null,
  dataList: null,
  totalItemsInList: 0,
  isLoading: true,
  isLoadingDeviceTypes: true,
  isLoadingPackageTypes: true,
  isLoadingPackageNames:true,
  isLoadingLocations: true,
  deviceTypesList: null,
  packageTypesList: null,
  packageNamesList:null,
  locationsList: null,
}

export const reducer = (state = initialState, {type, ...rest}) => {
  switch (type) {
    case CLIENTS_SELECT_ITEM:
      return {...state, ...rest}
    case getClientsNext.INIT:
      return {...state, isLoading: true}
    case getClientsNext.SUCCESS:
      return {...state, dataList: rest?.payload?.list, totalItemsInList: rest?.payload?.totalItems, isLoading: false}
    case getDeviceTypesNext.SUCCESS:
      return {...state, deviceTypesList: rest?.payload, isLoadingDeviceTypes: false}
    case getPackageTypesNext.SUCCESS:
      return {...state, packageTypesList: rest?.payload, isLoadingPackageTypes: false}

    case getClientsNext.ERROR:
      return {...state, isLoading: false}
    case getDeviceTypesNext.ERROR:
      return {...state,  isLoadingDeviceTypes: false}
    case getPackageTypesNext.ERROR:
      return {...state, isLoadingPackageTypes: false}

      case EXPORT_FILE_INIT:
        return {...state, isLoading: true}
      case EXPORT_FILE_SUCCESS:
      return {...state, isLoading: false}
    case EXPORT_FILE_ERROR:
      return {...state, isLoading: false};
    default:
      return state
  }
}


import { getNotificationEventsNext, getModalNotificationEventsNext } from './actionType';

const initialState = {
  notificationEvents: null,
  modalNotificationEvents: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getNotificationEventsNext.INIT:
      return { ...state, notificationEvents: null }
    case getNotificationEventsNext.SUCCESS:
      return { ...state, notificationEvents: payload }
    case getModalNotificationEventsNext.INIT:
      return { ...state, modalNotificationEvents: null }
    case getModalNotificationEventsNext.SUCCESS:
      return { ...state, modalNotificationEvents: payload }
    default:
      return state
  }
}

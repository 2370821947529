import { getCampaignListNext, createCampaignNext } from "./actionType";

const initialState = {
  datalist: [],
  isLoading: false,
  totalItemsInList: 0,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getCampaignListNext.INIT:
      return { ...state, isLoading: true };
    case getCampaignListNext.SUCCESS:
      return {
        ...state,
        datalist: payload?.list,
        totalItemsInList: payload?.totalItems,
        isLoading: false,
      };
    case getCampaignListNext.ERROR:
      return { ...state, isLoading: false, datalist: null };

    case createCampaignNext.INIT:
      return { ...state, isLoading: true };
    case createCampaignNext.SUCCESS:
      return { ...state, isLoading: false };
    case createCampaignNext.ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

import {NextApi} from "../../../../redux/service/Next/next";

export const CLIENTS_SELECT_ITEM = "CLIENTS_SELECT_ITEM"
export const CLIENTS_IS_OPEN_MODAL_CREATE_NEW = 'CLIENTS_IS_OPEN_MODAL_CREATE_NEW'

export const CLIENTS_GET="CLIENTS_GET"
export const DEVICE_TYPES_GET='DEVICE_TYPES_GET'
export const PACKAGE_TYPES_GET='PACKAGE_TYPES_GET'
export const EXPORT_CSV = 'EXPORT_CSV'

export const EXPORT_FILE_INIT = 'EXPORT_FILE_INIT';
export const EXPORT_FILE_SUCCESS = 'EXPORT_FILE_SUCCESS';
export const EXPORT_FILE_ERROR = 'EXPORT_FILE_ERROR';

export const getClientsNext = new NextApi("user-info/operator/users", CLIENTS_GET, {
  method: "GET", json: false,
});

export const getDeviceTypesNext = new NextApi("device-manager/device-type", DEVICE_TYPES_GET, {
  method: "GET", json: false,
});

export const getPackageTypesNext = new NextApi("whitelabel/get-all-package-types", PACKAGE_TYPES_GET, {
  method: "GET", json: false,
});

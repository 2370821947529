import React from 'react';
import './IensoRadioBtn.scss'
import PropTypes from "prop-types";
import classnames from "classnames";

export const IensoRadioBtn = React.memo(({label, checked, id, onChange,disabled, name, itemName}) => {
  return (
    <div className={classnames('ienso-radio-btn', disabled && "disabled")}>
      <input type="radio" name={name} itemName={itemName} checked={checked} onChange={onChange} disabled={disabled}/>
      <label htmlFor="test1">{label}</label>
    </div>
  );
})

IensoRadioBtn.defaultProps = {
  label: '',
  id: 'radioBtn',
  onChange:()=>undefined,
  disabled:false
}
IensoRadioBtn.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onChange:PropTypes.func,
  disabled:PropTypes.bool
}

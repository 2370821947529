import React from 'react';
import 'react-dates/initialize';
import {BrowserRouter} from 'react-router-dom';
import './scss/style.scss';
import RealmNameWrapper from "./app-initialization-wrappers/RealmNameWrapper";
import AppRoutes from "./AppRoutes";
import UserWrapper from "./app-initialization-wrappers/UserWrapper";
import LanguageInitWrapper from "./app-initialization-wrappers/LanguageInitWrapper";
import {IensoLoader} from "./iensoink/ui-components";
import CommonAppDataLoadingWrapper from "./app-initialization-wrappers/CommonAppDataLoadingWrapper";
import HelmetWrapper from "./app-initialization-wrappers/HelmetWrapper";


// Containers

const App = () => (
  <BrowserRouter basename={"/operator"}>
    <React.Suspense fallback={<IensoLoader/>}>
      <RealmNameWrapper>
        <UserWrapper>
          <LanguageInitWrapper>
            <CommonAppDataLoadingWrapper>
              <HelmetWrapper>
                <AppRoutes/>
              </HelmetWrapper>
            </CommonAppDataLoadingWrapper>
          </LanguageInitWrapper>
        </UserWrapper>
      </RealmNameWrapper>
    </React.Suspense>
  </BrowserRouter>
);


export default App;

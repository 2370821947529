import { NextApi } from 'src/redux/service/Next/next';

export const ADMIN_USER_EVENTS = 'ADMIN_USER_EVENTS';
export const MODAL_ADMIN_USER_EVENTS = 'MODAL_ADMIN_USER_EVENTS';

export const getAdminUserEventsNext = new NextApi(`analytics/users`, ADMIN_USER_EVENTS, {
  method: 'GET', json: false,
});

export const getModalAdminUserEventsNext = new NextApi(`analytics/users`, MODAL_ADMIN_USER_EVENTS, {
  method: 'GET', json: false,
});

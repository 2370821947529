import React from 'react';
import './IensoLoader.scss'

export const IensoLoader = ({marginTop, className}) => {
  return (
    <div className={"d-flex justify-content-center align-items-center ienso-loader " + (className || '')} style={{marginTop}}>
      <div className="sk-swing">
        <div className="sk-swing-dot"/>
        <div className="sk-swing-dot"/>
      </div>
    </div>
  );
};

IensoLoader.defaultProps={
  marginTop:'45vh'
}

import { getFirmwareListNext, getFilteredFirmwareListNext } from "./actionTypes";


const initialState = {
  isLoading: true,
  list: null,
  filteredListIsLoading: true,
  filteredList: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getFirmwareListNext.INIT:
      return { ...state, isLoading: true }
    case getFirmwareListNext.SUCCESS:
      return { ...state, list: payload, isLoading: false }
    case getFirmwareListNext.ERROR:
      return { ...state, isLoading: false }
    case getFilteredFirmwareListNext.SUCCESS:
      return { ...state, filteredList: payload, filteredListIsLoading: false }
    case getFilteredFirmwareListNext.ERROR:
      return { ...state, filteredListIsLoading: false }
    default:
      return state
  }
}


import React from 'react';
import './IensoNavigation.scss'
import {CSidebar} from '@coreui/react'
import './IensoNavigation.scss'
import '../../scss/styles.scss'
import classnames from "classnames";
import PropTypes from "prop-types";

export const IensoNavigation = React.memo(({children, show, onShowChange}) => (
<div className={classnames('iensoNavigation')}>
    <CSidebar
      show={show}
      unfoldable
      onShowChange={onShowChange}
    >
      {children}
    </CSidebar>
</div>
))

IensoNavigation.propTypes={
  children:PropTypes.node,
  show:PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onShowChange:PropTypes.func
}

IensoNavigation.defaultProps={
  show:false,
  onShowChange:()=>undefined,
}

import React, {useEffect, useState} from 'react';
import Select from "react-select";
import classnames from "classnames";
import {CFormGroup, CInputGroup, CInputGroupPrepend, CInputGroupText, CLabel} from "@coreui/react";
import PropTypes from "prop-types";
import '../IensoInputsGroup.scss'
import './IensoMultiSelect.scss'

export const IensoMultiSelect =  React.memo(({label, icon, options, id, value, useIcon, onChange}) => {
  const [val, setVal] = useState(value)
  useEffect(() => {
    setVal(value)
  }, [value])




  return (
    <div
      className={classnames('ienso-input-group  ienso-inputs-group ienso-multi-select', useIcon ? 'input-rounded-border-right' : 'input-rounded-border')}>
      <CFormGroup>
        <CLabel>{label}</CLabel>
        <CInputGroup>
          {useIcon && <CInputGroupPrepend>
            <CInputGroupText className={'text-white'}>
              <div className={'text-field-icon'}>{icon}</div>
            </CInputGroupText>
          </CInputGroupPrepend>}
          <Select
            className={'custom-multi-select'}
            classNamePrefix="custom-multi-select"
            value={val}
            options={options}
            onChange={onChange}
            id={id}
            name={id}
            isMulti
            closeMenuOnSelect={false}
          />
        </CInputGroup>
      </CFormGroup>
    </div>
  );
})

IensoMultiSelect.propTypes = {
  options: PropTypes.array,
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string
}

IensoMultiSelect.defaultProps = {
  options: null,
  id: '',
  label: '',
  className: ''
}
